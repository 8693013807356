import axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { useAuth } from "../../context/authContext";
import { BASE_URL } from "../../utils/constants";
import VideoCard from "./VideoCard";
import "./videos_page.scss";

const VideosPage = () => {

    const [videosInformation, setVideosInformation] = useState([]);
    const auth = useAuth();

    const videoInfoAPICall = async () => {
        try {
            const response = await axios.get(BASE_URL+`/videos?type=doctor`);
            setVideosInformation(response.data.videos);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        videoInfoAPICall();
    }, auth);

    return (
        <div>
            <Header/>
            <div className="videos-container">
                <div className="videos-wrapper">
                    {videosInformation && videosInformation.map((videoInfo, index) => (
                        <VideoCard
                            key={index}
                            videoInfo={videoInfo}
                        ></VideoCard>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default VideosPage;
