import React,{ useEffect } from 'react'
import "./accounts.scss"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Header from '../../components/Header/Header';
import { useAccountPaymentContext } from '../../context/accountContext';


export default function Account() {
   const {accountInfoFun,accountPaymentInfo} = useAccountPaymentContext()
   const {total_payable,total_commission,total_paid,total_due} = accountPaymentInfo

    useEffect(()=>{
      accountInfoFun()
    },[])


    return (
        <>
        <Header/>
        <div className='accounts-container'>
            <div className="accounts-wrapper">
                <div className="box">
                    <h3 style={{color: "#3498db"}}>Total Payable</h3>
                    <h2>{total_payable} <span>৳</span></h2>
                </div>
                <div className="box">
                    <h3 style={{color: "#2ecc71"}}>Total Due</h3>
                    <h2>{total_due}<span>৳</span></h2>
                </div>
                <div className="box">
                    <h3 style={{color: "#fd79a8"}}>Total Paid</h3>
                    <h2>{total_paid}<span>৳</span></h2>
                </div>
                <div className="box">
                    <h3 style={{color: "#3dc1d3"}}>Total Commission</h3>
                    <h2>{total_commission}<span>৳</span></h2>
                </div>
            </div>
            <div className='text'>
               <h3>Payments</h3>
                <div className='t'><ArrowRightAltIcon/> <p> Usually we pay every month twice. If you need any amount you can call to the customer support about it</p></div>
                <div className='t'><ArrowRightAltIcon/> <p>  You can add or change your bank information any time</p></div>
                <div className='t'><ArrowRightAltIcon/> <p> Currently we are charging 15% from every appointment</p></div>
            </div>
        </div>
        </>
    )
}
