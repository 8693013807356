import axios from "axios";
import { createContext, useContext, useState } from "react";
import { BASE_URL } from "../../utils/constants";
import { useAuth } from "../authContext";

const todayUrgentAppointmentsContext = createContext();

export const TodaysUrgentAppointmentsContextProvider = (({ children }) => {

    const [todayUrgentAppointments, setTodaysUrgentAppointments] = useState([]);
    const auth = useAuth();
    const loginInfo = JSON.parse(localStorage.getItem('logged'));


    const todaysUrgentAppointmentsInfoFunction = async (pageNumberTodaysUrgent) => {
        try {
            const response = await axios.get(BASE_URL+`/appointment/list/doctor?page=${pageNumberTodaysUrgent}&limit=10&all=0&type=instant`, {
                headers: {
                  'authorization': `${loginInfo.token}`
                }
            });

            if(pageNumberTodaysUrgent === 1) {
                setTodaysUrgentAppointments([]);
                setTodaysUrgentAppointments(response.data.appointments);
            } else {
                setTodaysUrgentAppointments((previousData) => [...previousData, ...response.data.appointments]);
            }
            
            //console.log(response.data.appointments);
        } catch (error) {
            console.error(error);
        }
    }

    return  <todayUrgentAppointmentsContext.Provider value = {{todayUrgentAppointments, todaysUrgentAppointmentsInfoFunction}}>{children}</todayUrgentAppointmentsContext.Provider>
})

export const useTodaysUrgentAppointmentsContext = () => {
    const {
        todayUrgentAppointments,
        todaysUrgentAppointmentsInfoFunction
    } = useContext(todayUrgentAppointmentsContext)
  
    return {
        todayUrgentAppointments,
        todaysUrgentAppointmentsInfoFunction
    }
}

