import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate()
  const token = JSON.parse(localStorage.getItem('logged'))

  if (!token) {
    return <Navigate to="/login" replace />
  }
  else {
    return children
  }
};

export default ProtectedRoute;
