import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Card, IconButton } from '@mui/material';
import { purple } from "@mui/material/colors";
import { Box } from '@mui/system';
import React from 'react';
import './day-month-medicine.scss';

const DayMonthMedicine = (props) => {
    const { id, medicineInfo, updateIndex} = props;
    
    const handleDeleteItem = (id) => {
        //alert(id);
        props.onRemoveMedicineData(id);
    };

    const handleEditItem = (id, updateIndex) => {
        props.onEditableRemoveMedicineInfo(id, updateIndex);
    }

    return (
        <div>
            <Box sx={{ width: "92%", px: 1}}>
                <div className="medicine-day-months-container">
                    <Card variant="outlined" sx={{ display: "flex" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                            }}
                        >
                            <div className="information-container">
                                <h5>Medicine Name: {medicineInfo.medicineName}</h5>
                                <h5>{medicineInfo.days} Days</h5>
                                <h5>{medicineInfo.month} Months</h5>
                                <h5>comment: {medicineInfo.comment}</h5>
                            </div>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                pr: 1,
                            }}
                        >
                            <IconButton aria-label="edit-button">
                                <EditIcon
                                    sx={{
                                        height: 25,
                                        width: 25,
                                        color: purple[800],
                                    }}
                                    onClick={() => {
                                        handleEditItem(id, updateIndex);
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                aria-label="delete-button"
                                color="error"
                                onClick={() => {
                                    handleDeleteItem(id);
                                }}
                            >
                                <DeleteIcon
                                    sx={{ height: 25, width: 25 }}
                                />
                            </IconButton>
                        </Box>
                    </Card>
                </div>
            </Box>
        </div>
    );
};

export default DayMonthMedicine;