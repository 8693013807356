import React, { useEffect, useState } from "react";
import CardItem from "../../components/CardItem/CardItem";
import Loading from "../../components/Loading/Loading";
import { useAuth } from "../../context/authContext";
import { useTodaysNormalAppointmentsContext } from "../../context/todaysAppointment/todaysNormalAppointmentContext";
import "../AllAppointments/allAppointments.scss";

const NormalAppointments = ({todayAppointmentNormalInitialPageNumber}) => {

    const {todaysNormalAppointments, todaysNormalAppointmentsInfoFunction} = useTodaysNormalAppointmentsContext();
    const [todaysNormalAppointmentPageNumber, setTodaysNormalAppointmentPageNumber] = useState(todayAppointmentNormalInitialPageNumber);
    const [loading, setLoading] = useState(true);

    const auth = useAuth();

    useEffect(() => {
        window.addEventListener("scroll", handelInfiniteScroll);
        return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }, []);


    const handelInfiniteScroll = async () => {
        try {
            if (
            window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight
            ) {
                setLoading(true);
                setTodaysNormalAppointmentPageNumber((prev) => prev + 1);
            }
        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        todaysNormalAppointmentsInfoFunction(todaysNormalAppointmentPageNumber);
        setLoading(false);
    }, [todaysNormalAppointmentPageNumber]);

    //console.log(todaysAppointments);

    if(todaysNormalAppointments.length <= 0){
        return (
            <div className="no-data-found-container">
                <h3>No Appointment found</h3>
            </div>
        );
    }

    return (
        <div className="normal-appointments-container">
            <div className="card-items">
                {
                    todaysNormalAppointments && todaysNormalAppointments.map((item, index) => <CardItem key={index} item={item}></CardItem>)
                }
                {
                     console.log(todaysNormalAppointments.length)
                }
            </div> 
            {loading && <Loading></Loading>}       
        </div>
    );
};

export default NormalAppointments;
