import React from 'react'
import './dashboard.scss'
import img from '../../assets/pexels-antoni-shkraba-5215024.jpg'
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SettingsIcon from '@mui/icons-material/Settings';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Header from "../../components/Header/Header"
import { useNavigate } from 'react-router-dom';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';




export default function Dashboard() {
  const navigate = useNavigate();

    return (
        <>
        <Header/>
        <div className='dashboard-container'>    
            <div className="dashboard-wrapper">
                <div className=" box box1" onClick={() => navigate("/appointments")}>
                    <DateRangeIcon className='icon'/>
                    <h3>Today's </h3>
                    <h3>Appointment</h3>
                </div>
                <div className="box box2" onClick={() => navigate('/allappointments')}> 
                    <BookOnlineIcon className='icon'/>
                    <h3>All </h3>
                    <h3>Appointment</h3>
                </div>
                <div className="box box3" onClick={() => navigate('/accounts')}>
                    <AccountBalanceWalletIcon className='icon'/>
                    <h3>My Account</h3>
                </div>
                <div className="box box4">
                    <LiveTvIcon className='icon icon-4'/>
                    <h3>Live </h3>
                    <h3>Appointments</h3>
                </div>
                <div className="box box5" onClick={()=> navigate("/profilesettings")}>
                    <ManageAccountsIcon className='icon'/>
                    <h3>Profile Settings</h3>
                </div>
                <div className="box box6" onClick={() => navigate("/videos")}>
                    <OndemandVideoIcon className='icon'/>
                    <h3>Videos</h3>
                </div>
            </div>
        </div>
        </>
    )
}
