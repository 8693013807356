import {
    Card,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import Header from "../../components/Header/Header";
import "./view_prescription.scss";

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const pData = {};

export const dummyFunction = (data, patientInformationForPrescriptionPage) => {
     console.log(data);
     pData = data;
     console.log(patientInformationForPrescriptionPage);
}

const rows = [createData("Test Name", "23Y", "Male", "70", "O(+ve)")];


const ViewPrescription = () => {

    const [patientData, setPatientData] = useState(pData);
    const [patientPrescriptionData, setPatientPrescriptionData] = useState({});
    
    return (
        <>
            <Header></Header>
            <div className="view-prescription-container">
                <div className="view-prescription-paper-items">
                    <Card
                        variant="outlined"
                        sx={{
                            p: 1,
                            width: 1000,
                        }}
                    >
                        <div className="logo-container">
                            <img src="logo.png" alt="" srcset="" />
                        </div>
                        <div className="prescription-header">
                            <Typography
                                variant="h5"
                                component="h2"
                                sx={{ fontWeight: "bold" }}
                            >
                                Mr. Healer
                            </Typography>
                            <Typography
                                sx={{ fontWeight: "bold" }}
                                variant="h6"
                                component="h2"
                            >
                                E-prescription
                            </Typography>
                            <div className="date-and-time-container">
                                <Typography variant="subtitle2" component="h2">
                                    27-12-2022&nbsp;
                                </Typography>
                                <Typography variant="subtitle2" component="h2">
                                    10:12 PM
                                </Typography>
                            </div>
                            <Typography variant="subtitle2" component="h2">
                                Ref: 446330
                            </Typography>
                            <br />
                        </div>
                        <Divider />
                        <div className="doctor-info">
                            <Typography
                                variant="subtitle2"
                                component="h2"
                                sx={{ fontWeight: "bold" }}
                            >
                                Z. Mr. Healer
                            </Typography>
                            <Typography variant="subtitle2" component="h2">
                                MBBS
                            </Typography>
                            <Typography variant="subtitle2" component="h2">
                                Account Holder: Mr Healer Ltd
                            </Typography>
                        </div>
                        <br />

                        {/* patient table info started */}
                        <div className="patient-table-info">
                            <TableContainer component={Paper}>
                                <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: "bold" }}>
                                                Patient Name
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontWeight: "bold" }}
                                                align="right"
                                            >
                                                Age
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontWeight: "bold" }}
                                                align="right"
                                            >
                                                Gender
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontWeight: "bold" }}
                                                align="right"
                                            >
                                                Weight
                                            </TableCell>
                                            <TableCell
                                                sx={{ fontWeight: "bold" }}
                                                align="right"
                                            >
                                                Blood Group
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        { border: 0 },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.calories}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.fat}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.carbs}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.protein}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <br />
                        {/* other info started */}
                        <div className="other-info-container">
                            <Typography
                                variant="h6"
                                component="h2"
                                sx={{ fontWeight: "bold" }}
                            >
                                CC:
                            </Typography>
                            <Typography
                                variant="h6"
                                component="h2"
                                sx={{ fontWeight: "bold" }}
                            >
                                test
                            </Typography>
                        </div>
                        <br />
                        <hr />
                        <br />
                        <Typography
                            variant="h6"
                            component="h2"
                            sx={{ fontWeight: "bold" }}
                        >
                            Rx
                        </Typography>
                        <hr />
                        <br />
                        <div className="test-container">
                            <Typography
                                variant="h6"
                                component="h2"
                                sx={{ fontWeight: "bold" }}
                            >
                                Test
                            </Typography>
                            {/* test-body container */}
                            <div className="test-body-container">
                                <ol>
                                    <li>Test 1</li>
                                    <li>Test 2</li>
                                    <li>Test 3</li>
                                    <li>Tesfdddddddddd</li>
                                    <li>Tessdffffffffffffffff</li>
                                    <li>Tedffffffffffffffffffffff</li>
                                    <li>Tesdfffffffffffffffffff</li>
                                </ol>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default ViewPrescription;
