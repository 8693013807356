import axios from "axios";
import { createContext, useContext, useState } from "react";
import { BASE_URL } from "../../utils/constants";
import { useAuth } from "../authContext";

const viewDetailsContext = createContext();

export const ViewDetailsContextProvider = (({ children }) => {

    const [viewDetailsInfoOfPatient, setViewDetailsInfoOfPatient] = useState([]);
    const auth = useAuth();
    const loginInfo = JSON.parse(localStorage.getItem('logged'));


    const viewDetailsInfoFunction = async (patientID, appointmentID) => {
        try {
            const response = await axios.get(BASE_URL+`/appointment/list/doctor_history?patient_id=${patientID}`, {
                headers: {
                  'authorization': `${loginInfo.token}`
                }
            });

            //console.log(response.data);
            const filterData = response.data.history;
            const filterItems = filterData.filter((item) => item.appointment_id !== appointmentID)
            setViewDetailsInfoOfPatient(filterItems);


        } catch (error) {
            console.error(error);
        }
    }

    return  <viewDetailsContext.Provider value = {{viewDetailsInfoOfPatient, viewDetailsInfoFunction}}>{children}</viewDetailsContext.Provider>
})

export const useViewDetailsContext = () => {
    const {
        viewDetailsInfoOfPatient,
        viewDetailsInfoFunction,
        
    } = useContext(viewDetailsContext)
  
    return {
        viewDetailsInfoOfPatient,
        viewDetailsInfoFunction,
    }
}