import axios from "axios";
import { createContext, useContext, useState } from "react";
import { BASE_URL } from "../../utils/constants";
import { useAuth } from "../authContext";

const notificationContext = createContext();

export const NotificationContextProvider = (({ children }) => {

    const [notifications, setNotifications] = useState([]);
    const [notificationsUnseenCount, setNotificationsUnseenCount] = useState(0);
    const auth = useAuth();
    const loginInfo = JSON.parse(localStorage.getItem('logged'));


    const notificationsFetchFunction = async () => {
        try {
            const response = await axios.get(BASE_URL+`/notification/doctor`, {
                headers: {
                  'authorization': `${loginInfo.token}`
                }
            });
            setNotifications(response.data.notifications);
            // console.log(response);
        } catch (error) {
            console.error(error);
        }
    }

    const notificationsUnseenCountFetchFunction = async () => {
        try {
            const response = await axios.get(BASE_URL+`/notification/doctor/unseen_count`, {
                headers: {
                  'authorization': `${loginInfo.token}`
                }
            });
            setNotificationsUnseenCount(response.data.notifications[0].count);
            //console.log(response.data.notifications[0].count);
        } catch (error) {
            console.error(error);
        }
    }

    return  <notificationContext.Provider value = {{notifications, notificationsUnseenCount, notificationsFetchFunction, notificationsUnseenCountFetchFunction}}>{children}</notificationContext.Provider>
})

export const useNotificationsContext = () => {
    const {
        notifications,
        notificationsUnseenCount,
        notificationsFetchFunction,
        notificationsUnseenCountFetchFunction
    } = useContext(notificationContext)
  
    return {
        notifications,
        notificationsUnseenCount,
        notificationsFetchFunction,
        notificationsUnseenCountFetchFunction
    }
}
