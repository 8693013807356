import React, { useEffect } from 'react';
import "./notifications.scss";

import Header from "../../components/Header/Header";
import { useNotificationsContext } from '../../context/notification/notificationContext';

export default function Notifications() {

    const {notifications, notificationsFetchFunction} = useNotificationsContext();

    useEffect(() => {
        notificationsFetchFunction();
    }, [])

    console.log(notifications);

    return (
        <>
            <Header/>
            <div className="notifications-container">
            <div className="notifications-wrapper">
                {notifications && notifications.map((notification, index) => (
                    <div className='notification'>
                        <p>{notification.content}</p>
                    </div>
                ))}
            </div>
            </div>

        </>
    )
}

