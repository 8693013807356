import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, InputAdornment, TextField } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UpdateIcon from '@mui/icons-material/Update';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import "./profile.scss"
import { useEducationContext } from '../../context/profile/educationContext';




export default function UpdateEducation(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('body'); 
  const { updateEducation,getEducationById,singleEducation,setSingleEducation} = useEducationContext()
 
  const {handleSingleEducation,data} = props.value
  const {doctor_education_id,institute,degree,start_year,end_year} = singleEducation



  const handleEducation = () => {
   updateEducation(doctor_education_id)
    setOpen(false)
  }


  const handleClose = () => {
    setOpen(false)
  }

  const handleClickOpen = (scrollType) => () => {
   handleSingleEducation(data)
    setOpen(true);
    setScroll(scrollType);
  };



  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
     <Button
        onClick={handleClickOpen('body')}
        variant="outlined" 
        sx={{ mr: 2 }}>
        <UpdateIcon />
    </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add Education</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ width: 500 }}
          >

            <TextField
              sx={{ display: 'block', mt: 2 }}
              id="outlined-required"
              label="Institution Name"
              value={institute}
              defaultValue=""
              size='medium'
              fullWidth
              onChange={(e) => setSingleEducation({doctor_education_id: doctor_education_id,institute:e.target.value,degree:degree,start_year:start_year,end_year:end_year})}
            />

            <TextField
              sx={{ display: 'block', mt: 3 }}
              id="outlined-required"
              label="Degree"
              value={degree}
              defaultValue=""
              size='medium'
              fullWidth
              onChange={(e) => setSingleEducation({doctor_education_id: doctor_education_id,institute:institute,degree:e.target.value,start_year:start_year,end_year:end_year})}
            />
            <TextField
              sx={{ display: 'block', mt: 3 }}
              id="outlined-required"
              label="Starting Year"
              value={start_year}
              
              defaultValue=""
              size='medium'
              fullWidth
              onChange={(e) => setSingleEducation({doctor_education_id: doctor_education_id,institute:institute,degree:degree,start_year:e.target.value,end_year:end_year})}
            />

            <TextField
              sx={{ display: 'block', mt: 3, mb: 3 }}
              id="outlined-required"
              label="Ending Year"
              value={end_year}
              defaultValue=""
              size='medium'
              fullWidth
              onChange={(e) => setSingleEducation({doctor_education_id: doctor_education_id,institute:institute,degree: degree,start_year: start_year,end_year:e.target.value})}
            />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'red' } }}>Cancel</Button>
         <Button variant="contained" color='secondary' onClick={handleEducation}>Update</Button>

        </DialogActions>
        <Divider />
      </Dialog>
    </div>
  );
}