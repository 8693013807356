import React from 'react'
import './bankInformation.scss'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Header from '../../../components/Header/Header'
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { useBankInfoContext } from '../../../context/profile/bankInfoContext';

export default function BankInformation() {
  const [bankingType, setBankingType] = React.useState('bank')

  const {
    bankInfo,
    setBankInfo,
    setupNewBanking,
    tempBankInfo,
    setTempBankInfo
  } = useBankInfoContext()

   

  const {
    banking_type,
    banking_name,
    account_number,
    routing_number,
    account_holder_name,
  } = tempBankInfo


  const handleBankingType = (bankingType) => {
    if(bankInfo.banking_type === bankingType){
      //setTempBankInfo({...tempBankInfo,banking_type:bankingType,banking_name: bankInfo.banking_name})
      setTempBankInfo({...bankInfo,banking_type:bankingType})

    }else {
      setTempBankInfo({...tempBankInfo,banking_type:bankingType,banking_name: '',account_number:''})
    }
  }

  return (
    <>
      <Header />
      <div className='bankInformation-container'>
        <div className="wrapper">
          <label className='label'>Select Banking Type</label>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{banking_type}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label=""
              onChange={(e) => handleBankingType(e.target.value)}

            >
              <MenuItem value="bank">Bank</MenuItem>
              <MenuItem value="mobileWallet">Mobile Wallet</MenuItem>

            </Select>
          </FormControl>

          {(banking_type === 'mobileWallet') ?
            <div>
              <label className='label'>Select Mobile Wallet</label>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{banking_name}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label=""
                  onChange={(e) => setTempBankInfo({ ...tempBankInfo, banking_name: e.target.value })}

                >
                  <MenuItem value="Bkas">Bkas</MenuItem>
                  <MenuItem value="Nagad">Nagad</MenuItem>
                  <MenuItem value="Rocket">Rocket</MenuItem>
                  <MenuItem value="Upay">Upay</MenuItem>

                </Select>
              </FormControl>

              <label className='label'>Account number</label>
              <TextField
                fullWidth
                id="outlined-basic"
                label=""
                defaultValue={account_number}
                variant="outlined" 
                onChange={(e) => setTempBankInfo({...tempBankInfo,account_number: e.target.value})}
              />

            </div>
            : <div>
              <label className='label'>Select Bank</label>
              <FormControl fullWidth >
              <InputLabel id="demo-simple-select-label">{banking_name}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label=""
                  onChange={(e) => setTempBankInfo({ ...tempBankInfo, banking_name: e.target.value })}
                >
                  <MenuItem value="Al-Arafah Islami Bank Limited">Al-Arafah Islami Bank Limited</MenuItem>
                  <MenuItem value="EXIM Bank Limited">EXIM Bank Limited</MenuItem>
                  <MenuItem value="First Security Islami Bank Limited">First Security Islami Bank Limited</MenuItem>
                  <MenuItem value="Global Islamic Bank Limited">Global Islamic Bank Limited</MenuItem>
                  <MenuItem value="Islami Bank Bangladesh Limited">Islami Bank Bangladesh Limited</MenuItem>
                  <MenuItem value="Shahjalal Islami Bank Limited">Shahjalal Islami Bank Limited</MenuItem>
                  <MenuItem value="Social Islami Bank Limited">Social Islami Bank Limited</MenuItem>
                  <MenuItem value="Union Bank Limited">Union Bank Limited</MenuItem>
                  <MenuItem value="Standard Bank Limited">Standard Bank Limited</MenuItem>
                  <MenuItem value="AB Bank Limited">AB Bank Limited</MenuItem>
                  <MenuItem value="Bangladesh Commerce Bank Limited">Bangladesh Commerce Bank Limited</MenuItem>
                  <MenuItem value="Bank Asia Limited">Bank Asia Limited</MenuItem>
                  <MenuItem value="Bengal Commercial Bank Limited">Bengal Commercial Bank Limited</MenuItem>
                  <MenuItem value="BRAC Bank Limited">BRAC Bank Limited</MenuItem>
                  <MenuItem value="Citizens Bank PLC">Citizens Bank PLC</MenuItem>
                  <MenuItem value="City Bank Limited">City Bank Limited</MenuItem>
                  <MenuItem value="Community Bank Bangladesh Limited">Community Bank Bangladesh Limited</MenuItem>
                  <MenuItem value="Dhaka Bank Limited">Dhaka Bank Limited</MenuItem>
                  <MenuItem value="Dutch Bangla Bank Limited">Dutch Bangla Bank Limited</MenuItem>
                  <MenuItem value="Eastern Bank Limited">Eastern Bank Limited</MenuItem>
                  <MenuItem value="IFIC Bank Limited">IFIC Bank Limited</MenuItem>
                  <MenuItem value="Jamuna Bank Limited">Jamuna Bank Limited</MenuItem>
                  <MenuItem value="Meghna Bank Limited">Meghna Bank Limited</MenuItem>
                  <MenuItem value="Mercantile Bank Limited">Mercantile Bank Limited</MenuItem>

                </Select>
              </FormControl>
              <div className='accInfo'>
                <label htmlFor="" className='l l3'>Account Number</label>
                <TextField
                  id="name"
                  multiline
                  defaultValue={account_number}
                  onChange={(e) => setTempBankInfo({ ...tempBankInfo, account_number: e.target.value })}
                />
                <label htmlFor="" className='l l4'>Routing Number (optional)</label>
                <TextField
                  id="name"
                  multiline
                  defaultValue={routing_number}
                  onChange={(e) => setTempBankInfo({ ...tempBankInfo, routing_number: e.target.value })}
                />
                <label htmlFor="" className='l l5'>Account Holder Name</label>

                <TextField
                  id="name"
                  multiline
                  defaultValue={account_holder_name}
                  onChange={(e) => setTempBankInfo({ ...tempBankInfo, account_holder_name: e.target.value })}
                />
              </div>

            </div>}
          <Button
            variant="contained"
            style={{ float: 'right', marginTop: '20px' }}
            onClick={setupNewBanking}
          >Save</Button>
        </div>
      </div>
    </>
  )
}
