import React from 'react'
import logo from '../../assets/logo (2).png'
import img from '../../../src/assets/pexels-antoni-shkraba-5215024.jpg'
import "./header.scss"
import Divider from '@mui/material/Divider';
import { NavLink } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Avatar from '@mui/material/Avatar';
import ProfileMenu from '../../material/Profile/ProfileMenu'
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Notifications from '../../material/notification/Notifications';
import AsideBar from '../../material/asidebar/AsideBar';




export default function Header() {
   const navigate = useNavigate()
   return (
      <div className="header-container">
         <div className="header-wrapper">
            <img src={logo} alt="" onClick={()=>navigate('/')}/>
            <div className='menu'>

               <div className='notification'>
                 <Notifications/>
               </div>
               <div className="m1">
                  <ProfileMenu/>
               </div>
               <Button  variant="contained" color='secondary' className='download-button'>App Download</Button>     
            </div>
            <span className='menu-icon'><AsideBar/></span>
         </div>

      </div>

   )
}
