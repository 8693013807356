import './App.css';
import Routing from './routes';
import Header from './components/Header/Header';
import { AppContextProvider } from './context/appContext';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { SnackbarProvider, useSnackbar } from 'notistack'

function App() {
  return (
    <div >
       <SnackbarProvider
         anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
       >
       <BrowserRouter>
              <AppContextProvider>
                <Routing />
            </AppContextProvider>
         </BrowserRouter>
         <ToastContainer/>
       </SnackbarProvider>
    </div>
  );
}

export default App;
