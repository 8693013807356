import axios from "axios";
import { createContext, useContext, useState } from "react";
import { BASE_URL } from "../../utils/constants";
import { useAuth } from "../authContext";

const urgentAppointmentsContext = createContext();

export const UrgentAppointmentsContextProvider = (({ children }) => {

    const [urgentAppointments, setUrgentAppointments] = useState([]);
    const auth = useAuth();
    const loginInfo = JSON.parse(localStorage.getItem('logged'));


    const urgentAppointmentsInfoFunction = async (pageNumberUrgent) => {
        try {
            const response = await axios.get(BASE_URL+`/appointment/list/doctor?page=${pageNumberUrgent}&limit=10&all=1&type=instant`, {
                headers: {
                  'authorization': `${loginInfo.token}`
                }
            });
            if(pageNumberUrgent === 1) {
                setUrgentAppointments([]);
                setUrgentAppointments(response.data.appointments);
            } else {
                setUrgentAppointments((previousData) => [...previousData, ...response.data.appointments]);
            }
            //console.log(response.data.appointments);
        } catch (error) {
            console.error(error);
        }
    }

    return  <urgentAppointmentsContext.Provider value = {{urgentAppointments, urgentAppointmentsInfoFunction}}>{children}</urgentAppointmentsContext.Provider>
})

export const useUrgentAppointmentsContext = () => {
    const {
        urgentAppointments,
        urgentAppointmentsInfoFunction
    } = useContext(urgentAppointmentsContext)
  
    return {
        urgentAppointments,
        urgentAppointmentsInfoFunction
    }
}

