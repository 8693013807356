import ShareIcon from "@mui/icons-material/Share";
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    IconButton,
    Typography
} from "@mui/material";
import React from "react";
import './video-card.scss';

const VideoCard = (props) => {

    const { title, thumbnail, url: videoLink } = props.videoInfo;
    let thumbnailLink = "https://healerspc.sgp1.digitaloceanspaces.com/media/thumbnails/"+thumbnail;

    async function share() {
        try {
          await navigator.share({
            text: 'Share this video with others',
            url: {videoLink}
          })
        } catch (error) {
          console.log('Sharing failed!', error)
        }
      }


    return (
        <div className="video-card" onClick={() => window.open(videoLink)}>
            <Card>
                <CardMedia
                    component="img"
                    height="100%"
                    image={thumbnailLink}
                    alt="Video Title"
                />
                <CardContent>
                    <Typography variant="h6" color="text.secondary">
                        {title}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton aria-label="share">
                        <ShareIcon onClick={() => share()}/>
                    </IconButton>
                </CardActions>
            </Card>
        </div>
    );
};

export default VideoCard;
