import CallIcon from "@mui/icons-material/Call";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useViewDetailsContext } from "../../context/viewDetails/viewDetailsContext";
import './card-item.scss';

const CardItem = ({item}) => {

    // console.log(item);

    const {viewDetailsInfoOfPatient, viewDetailsInfoFunction} = useViewDetailsContext();
    

    const {patient_id, patient_name, actual_profile_picture, appointment_id, is_followup, time} = item;

    const dateTime = time;
    const date = dateTime.split(' ').at(0);
    const showTime = dateTime.split(' ').at(1);
    const meridiemType = dateTime.split(' ').at(2);


    function isImage(profilePicture) {
        const result = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(profilePicture);
        // console.log(result);
        return result;
    }

    const viewDetailsFunction = () => {
        viewDetailsInfoFunction(patient_id, appointment_id);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <div className="card-item-container">
            <Card
              style={{boxShadow:'0px 0px 0px'}}
            >
                <Card sx={{ display: "flex" }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 152, px: 2, py: 2, height: 189 }}
                        image={isImage(actual_profile_picture) ? actual_profile_picture : 'dummy-profile-pic.jpg'}
                        alt="Dummy Profile Picture"
                    />
                    <div className="card-user-info-part">
                        <h4>{patient_name}</h4>                      
                        <h5>Appointment ID: {appointment_id || "3545"}</h5>
                        <Typography
                            variant="subtitle2"
                            color="text.secondary"
                        >
                            Patient type: {is_followup === 0 ? 'Follow-up' : 'New'}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                        >
                            Date: {date}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                        >
                            Time: {showTime} {meridiemType} 
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            color="text.secondary"
                        >
                            Booking type: {item.appointment_type}
                        </Typography>
                    </div>
                </Card>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        p: 0.7,
                    }}
                >
                    <Link to={"/create_prescription"} state={{patient_name, patient_id, appointment_id, is_followup, item}}>
                        <IconButton aria-label="note">
                            {theme.direction === "rtl" ? (
                                <NoteAltIcon />
                            ) : (
                                <NoteAltIcon />
                            )}
                        </IconButton>
                    </Link>

                    <IconButton aria-label="call">
                        {theme.direction === "rtl" ? (
                            <CallIcon />
                        ) : (
                            <CallIcon />
                        )}
                    </IconButton>

                    <IconButton aria-label="video call">
                        {theme.direction === "rtl" ? (
                            <VideoCallIcon />
                        ) : (
                            <VideoCallIcon />
                        )}
                    </IconButton>
                    <Link style={{textDecoration: 'none'}} to={"/view_details"} state={{item}}>
                        <Button variant="outlined" onClick={viewDetailsFunction}>View Details</Button>
                    </Link>
                </Box>
            </Card>
        </div>
    );
};

export default CardItem;
