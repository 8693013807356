import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SegmentIcon from '@mui/icons-material/Segment';
import "./asideBar.scss"
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useProfileContext } from '../../context/profile/profileContext';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../context/authContext';

export default function AsideBar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { profile } = useProfileContext()
  const navigate = useNavigate()
  const auth = useAuth()

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <img
        style={{ width: '60px', height: '60px', borderRadius: '50%', margin: 'auto', display: 'block', marginTop: '20px' }}
        src="https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg?w=2000" alt="" />
      <h3 style={{ textAlign: 'center', padding: '10px 0', color: '#757575' }}>{profile.name}</h3>
      <Divider />

      <div
        onClick={() => navigate('/dashboard')}
        className='aside'>
        <DashboardOutlinedIcon />
        <NavLink className='aside-menu'>Dashboard</NavLink>
      </div>

      <div
        onClick={() => navigate('/profilesettings')}
        className='aside'>
        <ManageAccountsOutlinedIcon />
        <NavLink className='aside-menu'>Profile</NavLink>
      </div>

      <div
        onClick={() => navigate('/accounts')}
        className='aside'>
        <AccountBalanceWalletOutlinedIcon />
        <NavLink className='aside-menu'>My account</NavLink>
      </div>

      <div
        onClick={() => navigate("/appointments")}
        className='aside'>
        <DateRangeIcon />
        <NavLink className='aside-menu'>Today's appointment</NavLink>
      </div>

      <div
        onClick={() => navigate("/allappointments")}
        className='aside'>
        <ListAltIcon />
        <NavLink className='aside-menu'>All appointment</NavLink>
      </div>
      <div
        onClick={() => auth.logout()}
        className='aside'>
        <LogoutIcon />
        <NavLink className='aside-menu'>Logout</NavLink>
      </div>



      {/* <List>
        {['My account', 'Profile settings', 'Logout'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>  */}
    </Box>
  );

  return (
    <div className='asidebar-container'>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
        
           <MenuIcon onClick={toggleDrawer(anchor, true)} />
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}