import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../utils/constants";

const authContext = createContext(null)

export const AuthContextProvider = ({ children }) => {
    const [token, setToken] = useState('')
    const [user_id, setUserId] = useState('')

    const setUser = () => {
        const user = JSON.parse(localStorage.getItem('logged'));
        if (user) {
            setToken(user.token)
            setUserId(user.user_id)
        }
    }

    useEffect(() => {
        setUser()
    }, [])



    const login = async (data) => {
        const { phoneNumber, password } = data

        const payload = {
            phone: phoneNumber,
            password: password,
            deviceToken: 'sldkjfldskfjd',
            fcmToken: 'ljsldfsdfsdfsdfsdfsdf',
            userType: 'doctor'
        }


        // const res = await axios.post(BASE_URL + '/auth/login', payload)
        // const { token, user_id } = res.data
        //  const obj = { token, user_id }
        const res = await fetch(BASE_URL + '/auth/login', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        const result = await res.json()

        if (result.status) {
            const { token, user_id } = result
            const obj = { token, user_id }
            localStorage.setItem('logged', JSON.stringify(obj))
            return true
        }
        else {
            return false
        }

    }



    const logout = () => {
        localStorage.clear()
      
        if (!localStorage.getItem('logged')) {
            return true
        }
    }


    return <authContext.Provider value={{ token, user_id, login, logout }}>
        {children}
    </authContext.Provider>
}

export const useAuth = () => {
    const auth = useContext(authContext)

    return auth
}