import React from "react";
import {
  Route, Routes
} from "react-router-dom";
import Account from "../pages/accounts/Accounts";
import AllAppointments from "../pages/AllAppointments/AllAppointments";
import Appointments from "../pages/Appointments/Appointments";
import CreatePrescription from "../pages/create_prescription/CreatePrescription";
import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/login/Login";
import NotFound from "../pages/notFound/NotFound";
import Notifications from "../pages/notifications/Notifications";
import BankInformation from "../pages/profile/bankInformation/BankInformation";
import Education from "../pages/profile/education/Education";
import Experience from "../pages/profile/experience/Experience";
import Profile from "../pages/profile/Profile";
import Registration from "../pages/registration/Registration";
import VideosPage from "../pages/videos_page/VideosPage";
import ViewDetails from "../pages/view_details/ViewDetails";
import ViewPrescription from "../pages/view_prescription/ViewPrescription";
import { PasswordChange } from "../pages/password_change/PasswordChange";
import ProtectedRoute from "./protectedRoutes";
import Settings from "../pages/settings/Settings";


export default function Routing() {
   const loginInfo = JSON.parse(localStorage.getItem("logged"))       
  

  return (
    <Routes>
      <Route path="/login" element={<Login/>} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/profilesettings" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route path="/allappointments" element={<ProtectedRoute><AllAppointments/></ProtectedRoute>} />
      <Route path="/appointments" element={<ProtectedRoute><Appointments/></ProtectedRoute>} />
      <Route path="/accounts" element={<ProtectedRoute><Account /></ProtectedRoute>} />
      <Route path="/create_prescription" element={<ProtectedRoute><CreatePrescription /></ProtectedRoute>} />
      <Route path="/view_prescription" element={<ProtectedRoute><ViewPrescription /></ProtectedRoute>} />
      <Route path="/videos" element={<ProtectedRoute><VideosPage /></ProtectedRoute>} />
      <Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
      <Route path="/profilesettings/educations" element={<ProtectedRoute><Education /></ProtectedRoute>} />
      <Route path="/profilesettings/experience" element={<ProtectedRoute><Experience/></ProtectedRoute>} />
      <Route path="/profilesettings/bankInformation" element={<ProtectedRoute><BankInformation/></ProtectedRoute>} />
      <Route path="/view_details" element={<ProtectedRoute><ViewDetails/></ProtectedRoute>} />
      <Route path="/changepassword" element={<ProtectedRoute><PasswordChange/></ProtectedRoute>} />
      <Route path="/settingspage" element={<ProtectedRoute><Settings/></ProtectedRoute>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
