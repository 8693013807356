import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UpdateIcon from '@mui/icons-material/Update';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { useExperienceContext } from '../../context/profile/experienceContext';

export default function AddExperience(props) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');
  const [workplace, setWorkplace] = React.useState('')
  const [dept, setDept] = React.useState('')

  const [startingYear, setStartingYear] = React.useState('')
  const [endingYear, setEndingYear] = React.useState('')
  const [present, setPresent] = React.useState(true)


  const {
    experience,
    addExperience,
    deleteExperience,
    updateExperience,
    singleExperience,
    setSingleExperience
  } = useExperienceContext()

  const { work_place, department, designation, start_year, end_year } = singleExperience

  const {
    handleExperienceUpdate,
    data
  } = props.value

  const handleExperience = () => {
    updateExperience(data.doctor_experience_id)
    setOpen(false);
  }

  const handleYear = () => {
    if (present) {
      // setSingleExperience({work_place:work_place, department:department, designation:designation, start_year:start_year, end_year:"Present"})}
      setPresent(false)
    }
    else {
      setEndingYear("")
      setPresent(true)
    }
  }


  const handleClickOpen = (scrollType) => () => {
    setSingleExperience({ work_place: data.work_place, department: data.department, designation: data.designation, start_year: data.start_year, end_year: data.end_year })
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);





  return (
    <div>
      <Button
        onClick={handleClickOpen('body')}
        variant="outlined"
        sx={{ mr: 2 }}>
        <UpdateIcon />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Update Experience</DialogTitle>
        <DialogContent dividers={scroll === 'body'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ width: 500 }}
          >

            <TextField
              sx={{ display: 'block' }}
              id="outlined-required"
              label="Workplace Name"
              defaultValue=""
              size='medium'
              fullWidth
              value={work_place}
              onChange={(e) => setSingleExperience({ work_place: e.target.value, department: department, designation: designation, start_year: start_year, end_year: end_year })}
            />

            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel id="demo-simple-select-label">Please select department</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Please select department"
                onChange={(e) => setSingleExperience({ work_place: work_place, department: e.target.value, designation: designation, start_year: start_year, end_year: end_year })}
                value={department}
              >

                <MenuItem value="cardiology">Cardiology</MenuItem>
                <MenuItem value="pediatric">Pediatric</MenuItem>
                <MenuItem value="colorectal-sergary(piles)">Colorectal Surgary (Piles)</MenuItem>
                <MenuItem value="dermatology">Dermatology</MenuItem>
                <MenuItem value="dentistry">Dentistry</MenuItem>
                <MenuItem value="diabetology">Diabetology</MenuItem>
                <MenuItem value="ent">E.N.T</MenuItem>
                <MenuItem value="gastroenerology">Gastroenerology</MenuItem>
                <MenuItem value="general-physician">General Physician</MenuItem>
                <MenuItem value="gynecology">Gynecology</MenuItem>
                <MenuItem value="hematology">Hematology</MenuItem>
                <MenuItem value="nephrology">Nephrology</MenuItem>
                <MenuItem value="medicine">Medicine</MenuItem>
                <MenuItem value="neuro medicine">Neuro Medicine</MenuItem>
                <MenuItem value="nutritionist">Nutritionist</MenuItem>
                <MenuItem value="oncology">Oncology</MenuItem>
                <MenuItem value="orthopedics">Orthopedics</MenuItem>
                <MenuItem value="plastic surgary">Plastic Surgary</MenuItem>
                <MenuItem value="urology">Urology</MenuItem>
                <MenuItem value="opthalmology">Opthalmology</MenuItem>
                <MenuItem value="rheumatology">Rheumatology</MenuItem>
                <MenuItem value="endocrinology">Endocrinology</MenuItem>
                <MenuItem value="pulmonology">Pulmonology</MenuItem>
                <MenuItem value="neurosurgary">Neurosurgery</MenuItem>
                <MenuItem value="psychologist">Psychologist</MenuItem>
              </Select>
            </FormControl>

            <TextField
              sx={{ display: 'block', mt: 3 }}
              id="outlined-required"
              label="Designation"
              defaultValue=""
              size='medium'
              fullWidth
              value={designation}
              onChange={(e) => setSingleExperience({ work_place: work_place, department: department, designation: e.target.value, start_year: start_year, end_year: end_year })}

            />

            <TextField
              sx={{ display: 'block', mt: 3 }}
              id="outlined-required"
              label="Starting Year"
              defaultValue=""
              size='medium'
              fullWidth
              value={start_year}
              onChange={(e) => setSingleExperience({ work_place: work_place, department: department, designation: designation, start_year: e.target.value, end_year: end_year })}
            />
            <TextField
              sx={{ display: 'block', mt: 3 }}
              id="outlined-required"
              label="Ending Year"
              defaultValue=""
              size='medium'
              fullWidth
              value={end_year}
              onChange={(e) => setSingleExperience({ work_place: work_place, department: department, designation: designation, start_year: start_year, end_year: e.target.value })}
            />

            <FormGroup>
              <FormControlLabel control={<Checkbox color='secondary' onClick={handleYear} />} label="Present" />
            </FormGroup>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'red' } }}>Cancel</Button> */}
          <Button onClick={handleExperience} color='secondary' variant="contained">Update</Button>
        </DialogActions>
        <Divider />
      </Dialog>
    </div>
  );
}