import React from 'react'
import './experience.scss'
import Header from '../../../components/Header/Header'
import AddExperience from '../../../material/Profile/AddExperience'
import UpdateExperience from '../../../material/Profile/UpdateExperience'
import Swal from 'sweetalert2'
import { Button } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UpdateIcon from '@mui/icons-material/Update';
import { useExperienceContext } from '../../../context/profile/experienceContext'

export default function Experience() {
    const {
        experience,
        addExperience,
        deleteExperience,
        updateExperience
    } = useExperienceContext()

    const handleDelete = (doctor_experience_id) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            customClass: 'experienceswal',
        }).then((result) => {
            if (result.isConfirmed) {
                deleteExperience(doctor_experience_id)
            }
        })
    }


    const handleExperienceUpdate = (data) => {

    }

    return (
        <>
            <Header />
            <div className='experience-container'>
                <div className="wrapper" >
                    <AddExperience />
                    <div style={{ overflowX: 'auto' }}>
                        <table>
                            <tr>
                                <th>Work Place</th>
                                <th>Department</th>
                                <th>Designation</th>
                                <th>Starting Year</th>
                                <th>Ending Year</th>
                                <th>Actions</th>
                            </tr>
                            {experience.map(data => (
                                <tr>
                                    <td>{data.work_place}</td>
                                    <td>{data.department}</td>
                                    <td>{data.designation}</td>
                                    <td>{data.start_year}</td>
                                    <td>{data.end_year}</td>
                                    <td className='action'>
                                        <UpdateExperience value={{ handleExperienceUpdate, data }} />
                                        <Button
                                            variant="outlined"
                                            color='error'
                                            onClick={() => handleDelete(data.doctor_experience_id)}>
                                            <DeleteOutlineIcon />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
