import axios from "axios";
import { createContext, useContext, useState } from "react";
import { BASE_URL } from "../../utils/constants";
import { useAuth } from "../authContext";

const normalAppointmentsContext = createContext();

export const NormalAppointmentsContextProvider = (({ children }) => {

    const [normalAppointments, setNormalAppointments] = useState([]);
    const auth = useAuth();
    const loginInfo = JSON.parse(localStorage.getItem('logged'));


    const normalAppointmentsInfoFunction = async (pageNumberNormal) => {
        try {
            const response = await axios.get(BASE_URL+`/appointment/list/doctor?page=${pageNumberNormal}&limit=10&all=1&type=normal`, {
                headers: {
                  'authorization': `${loginInfo.token}`
                }
            });

            if(pageNumberNormal === 1){
                setNormalAppointments([]);
                setNormalAppointments(response.data.appointments);
            } else {
                setNormalAppointments((previousData) => [...previousData, ...response.data.appointments]);
            }
            
            
        } catch (error) {
            console.error(error);
        }
    }

    return  <normalAppointmentsContext.Provider value = {{normalAppointments, normalAppointmentsInfoFunction}}>{children}</normalAppointmentsContext.Provider>
})

export const useNormalAppointmentsContext = () => {
    const {
        normalAppointments,
        setNormalAppointments,
        normalAppointmentsInfoFunction,
        
    } = useContext(normalAppointmentsContext)
  
    return {
        normalAppointments,
        setNormalAppointments,
        normalAppointmentsInfoFunction,
    }
}


