import React, { useEffect, useState } from "react";
import CardItem from "../../components/CardItem/CardItem";
import Loading from "../../components/Loading/Loading";
import { useNormalAppointmentsContext } from "../../context/allAppointment/normalAppointmentsContext";
import { useAuth } from "../../context/authContext";
import "./allAppointments.scss";


const NormalAppointmentsOfAllAppointments = ({normalAppointmentInitialPageNumber}) => {

    console.log("page"+normalAppointmentInitialPageNumber);

    const {normalAppointments, setNormalAppointments, normalAppointmentsInfoFunction} = useNormalAppointmentsContext();
    const [normalAppointmentPageNumber, setNormalAppointmentPageNumber] = useState(normalAppointmentInitialPageNumber);
    const [loading, setLoading] = useState(true);

    const auth = useAuth();


    useEffect(() => {
        window.addEventListener("scroll", handelInfiniteScroll);
        return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }, []);

    const handelInfiniteScroll = async () => {
        try {
            if (
            window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight
            ) {
                setLoading(true);
                setNormalAppointmentPageNumber((prev) => prev + 1);
            }
        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        normalAppointmentsInfoFunction(normalAppointmentPageNumber);
        setLoading(false);
    }, [normalAppointmentPageNumber]);

    // console.log(normalAppointments);

    if(normalAppointments.length <= 0){
        return (
            <div className="no-data-found-container">
                <h3>No Appointment found</h3>
            </div>
        );
    }

    return (
        <div className="normal-appointments-container">
            <div className="card-items">
                {
                    normalAppointments && normalAppointments.map((item, index) => <CardItem key={index} item={item}></CardItem>)
                }
            </div>
            {loading && <Loading></Loading>}
        </div>
    );
};

export default NormalAppointmentsOfAllAppointments;
