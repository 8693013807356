import * as React from 'react';
import "./password_change.scss"
import Header from "../../components/Header/Header"

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { SnackbarProvider, useSnackbar } from 'notistack'
import { BASE_URL } from '../../utils/constants';

export const PasswordChange = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [passInfo, setPassInfo] = React.useState({ current_password: '', new_password: '', confirm_password: '' })
    const { current_password, new_password, confirm_password } = passInfo
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const loginInfo = JSON.parse(localStorage.getItem("logged"))


    const handleChangePassword = async () => {
        if (current_password === "") {
            enqueueSnackbar('Need current password')
        }
        else if (new_password === "") {
            enqueueSnackbar('Need new password')
        }
        else if(new_password.length !==6){
            enqueueSnackbar("New password contains at least 6 characters")
        }
        else if (new_password !== confirm_password) {
            enqueueSnackbar("Didn't match new password with confirm password")
        }
        else {
            const obj = {
                password: current_password,
                new_password: new_password,
                confirm_password: confirm_password
            }
            const res = await fetch(BASE_URL + `/auth/change_password`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    "authorization": `${loginInfo.token}`
                },
                body: JSON.stringify(obj)
            })
            const result = await res.json()
            if(result.status === true){
                enqueueSnackbar("Password changed succesfully")
            }
            else {
                enqueueSnackbar("Someting wrong")
            }
        }
    }


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (<>
        <Header />
        <div className="password-change-container">
            <h1>Change Password</h1>
            <p>Enter your new password and confirm</p>
            <div className="box">
                <FormControl sx={{ m: 1, width: '38ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Current password</InputLabel>
                    <OutlinedInput
                        onChange={(e) => setPassInfo({
                            current_password: e.target.value,
                            new_password: new_password,
                            confirm_password: confirm_password
                        })}
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>

                <FormControl sx={{ m: 1, width: '38ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">New password</InputLabel>
                    <OutlinedInput
                        onChange={(e) => setPassInfo({
                            current_password: current_password,
                            new_password: e.target.value,
                            confirm_password: confirm_password
                        })}

                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>

                <FormControl sx={{ m: 1, width: '38ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Confirm password</InputLabel>
                    <OutlinedInput
                        onChange={(e) => setPassInfo({
                            current_password: current_password,
                            new_password: new_password,
                            confirm_password: e.target.value
                        })}

                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                    />
                </FormControl>

                <Button
                    onClick={handleChangePassword}
                    sx={{ mt: 2 }}
                    variant='contained'
                    color='secondary'>
                    RESET PASSWORD
                </Button>

            </div>
        </div>
    </>)
}