import { AppBar, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Header from "../../components/Header/Header";
import "../AllAppointments/allAppointments.scss";
import NormalAppointments from "./NormalAppointments";
import UrgentAppointments from "./UrgentAppointments";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const Appointments = (props) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
        <Header/>
        <div className="all-appointments-container">
            <Box >
                <AppBar
                    position="sticky"
                    style={{ backgroundColor: "white", boxShadow: "none" }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Normal Appointments" sx={{ fontWeight: "bolder" }} {...a11yProps(0)} />
                        <Tab label="Urgent Appointments" sx={{ fontWeight: "bolder" }} {...a11yProps(1)} />
                    </Tabs>
                </AppBar>

                <TabPanel value={value} index={0} dir={theme.direction}>
                    <NormalAppointments todayAppointmentNormalInitialPageNumber={1}></NormalAppointments>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <UrgentAppointments todayAppointmentUrgentInitialPageNumber={1}></UrgentAppointments>
                </TabPanel>
            </Box>
        </div>
        </>
    );
   
};

export default Appointments;
