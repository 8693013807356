import React from 'react'
import './profile.scss'
import img from '../../assets/pexels-antoni-shkraba-5215024.jpg'
import { Button, InputAdornment, TextField } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SetupSchedule from '../../material/Profile/SetupSchedule';
import SetupAppointmentFee from '../../material/Profile/SetupAppointmentFee';
import AddEducation from '../../material/Profile/AddEducation';
import AddExperience from '../../material/Profile/AddExperience';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Header from '../../components/Header/Header';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useNavigate } from 'react-router-dom'
import { useProfileContext } from '../../context/profile/profileContext';

import { BASE_URL } from '../../utils/constants';

export default function Profile() {
  const [dp, setDp] = React.useState('')

  const navigate = useNavigate()

  const {
    profile,
    setProfile,
    updateProfile,
    update_profile_image,
    profilePicture,
    setProfilePicture,
    showImg,
    setShowImg
  } = useProfileContext()

  const {
    name,
    dept_name,
    phone,
    designation,
    profile_description,
    current_workplace,
    experience,
    schedules,
    profile_picture,
  } = profile


  const hanndleUpdate = () => {
    updateProfile()
  }

  return (
    <>
      <Header />
      <div className='profile-container'>
        <div className="wrapper">

          {showImg.db && <label for="dp" style={{display:"flex",justifyContent:'center',alignItems:'center'}}>
            <img src={`https://healerspc.sgp1.digitaloceanspaces.com/media/profile_pictures/${profile_picture}`} alt="dp" title='select image' />
            <div style={{marginTop:'50px',marginLeft:'-15px',cursor:'pointer'}}><CameraAltIcon /></div>
          </label>}
       


          {
            showImg.up &&
            profilePicture.picturePreview !== null && <label for="dp" style={{display:"flex",justifyContent:'center',alignItems:'center'}}>
              <img src={profilePicture.picturePreview} style={{
              width: '130px',
              height: '130px'
            }} />
             <div style={{marginTop:'50px',marginLeft:'-15px',cursor:'pointer'}}><CameraAltIcon /></div>
            </label>
            
          }


          <input
            type="file"
            id='dp'
            accept="image/*"
            onChange={(e) => {
              setProfilePicture({
                picturePreview: URL.createObjectURL(e.target.files[0]),
                pictureAsFile: e.target.files[0]
              })
              setShowImg({ db: false, up: true, uploadButton: true })
            }}
            style={{ display: "none" }}
          />
          {showImg.uploadButton && <p className='profile' onClick={update_profile_image}><Button variant='outlined'>Confirm upload</Button></p>}


          <div className='option'>
            <label htmlFor="name">Name</label>
            <TextField
              id="name"
              multiline
              defaultValue={name}
              onChange={(e) => setProfile({ ...profile, name: e.target.value })}
              sx={{ mb: 4 }}
            />

            <label htmlFor="dept">Department</label>
            <TextField
              id="dept"
              defaultValue={dept_name}
              multiline
              disabled
              size='medium'
              sx={{ mb: 4 }}
            />

            <label htmlFor="phone">Phone Number</label>
            <TextField
              id="phone"
              defaultValue={phone}
              multiline
              disabled
              size='medium'
              sx={{ mb: 4 }}
            />

            <label htmlFor="designation">Designation</label>
            <TextField
              id="designation"
              defaultValue={designation}
              multiline
              size='medium'
              sx={{ mb: 3 }}
              onChange={(e) => setProfile({ ...profile, designation: e.target.value })}

            />
            <label>Setup Schedule</label>
            <div style={{ marginBottom: '23px' }}><SetupSchedule /></div>
            <label>Setup Appointment Fee</label>
            <div style={{ marginBottom: '23px' }}><SetupAppointmentFee /></div>

            <label>Setup Education</label>
            <TextField
              onClick={() => navigate('/profilesettings/educations')}
              fullWidth
              id="outlined-required"
              label=""
              size='medium'
              InputProps={{
                endAdornment: <InputAdornment position="end"><KeyboardArrowRightIcon /></InputAdornment>,
                readOnly: true,
              }}
              sx={{ mb: 4 }}
            />

            <label>Setup Experience</label>
            <TextField
              onClick={() => navigate('/profilesettings/experience')}
              fullWidth
              id="outlined-required"
              label=""
              size='medium'
              InputProps={{
                endAdornment: <InputAdornment position="end"><KeyboardArrowRightIcon /></InputAdornment>,
                readOnly: true,
              }}
              sx={{ mb: 4 }}
            />

            <label>Setup Bank Information</label>
            <TextField
              onClick={() => navigate("/profilesettings/bankInformation")}
              fullWidth
              id="outlined-required"
              label=""
              size='medium'
              InputProps={{
                endAdornment: <InputAdornment position="end"><KeyboardArrowRightIcon /></InputAdornment>,
                readOnly: true,
              }}
              sx={{ mb: 4 }}
            />

            <label htmlFor="experience">Experience</label>
            <TextField
              id="experience"
              defaultValue={experience}
              multiline
              size='medium'
              sx={{ mb: 4 }}
              onChange={(e) => setProfile({ ...profile, experience: e.target.value })}
            />

            <label htmlFor="cwp">Current Working Place</label>
            <TextField
              id="cwp"
              size='medium'
              multiline
              defaultValue={current_workplace}
              sx={{ mb: 4 }}
              onChange={(e) => setProfile({ ...profile, current_workplace: e.target.value })}
            />

            <label htmlFor="pd">Profile Description</label>
            <TextField
              id="pd"
              multiline
              rows={4}
              defaultValue={profile_description}
              sx={{ mb: 4 }}
              onChange={(e) => setProfile({ ...profile, profile_description: e.target.value })}
            />

            <Button
              variant="contained"
              color='secondary'
              onClick={hanndleUpdate}
            >
              UPDATE
            </Button>

          </div>
        </div>
      </div>
    </>
  )
}
