import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Card, IconButton } from '@mui/material';
import { purple } from "@mui/material/colors";
import { Box } from '@mui/system';
import React from 'react';

const AdviceName = (props) => {
    const { id, advice } = props;

    //console.log(props);
    //console.log(testName);
    const handleDeleteAdvice = (id) => {
        props.onRemoveAdvice(id);
    };

    const handleEditAdvice = (id) => {
        //console.log(id);
        props.onEditAdvice(id);
    };

    return (
        <div className="editable-text-field">
            <Card variant="outlined" sx={{ display: "flex", border: "none", pl: 1}}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        p: 1,
                        width: "100%",
                        height: "50px",
                        border: "none",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <h4>{advice}</h4>
                    <div className="all-buttons">
                        <IconButton 
                            aria-label="edit-advice-button"
                            onClick={() => {
                                handleEditAdvice(id);
                            }}
                        >
                            <EditIcon
                                sx={{
                                    height: 25,
                                    width: 25,
                                    color: purple[800],
                                }}
                            />
                        </IconButton>
                        <IconButton
                            aria-label="delete-advice-button"
                            color="error"
                            onClick={() => {
                                handleDeleteAdvice(id);
                            }}
                        >
                            <DeleteIcon sx={{ height: 25, width: 25 }} />
                        </IconButton>
                    </div>

                </Box>

            </Card>
        </div>
    );
};

export default AdviceName;