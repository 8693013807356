import { AppBar, Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React from "react";
import Header from "../../components/Header/Header";
import "./allAppointments.scss";
import NormalAppointmentsOfAllAppointments from "./NormalAppointmentsOfAllAppointments";
import UrgentAppointmentsOfAllAppointments from "./UrgentAppointmentsOfAllAppointments";



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const AllAppointments = () => {

    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Header />
            <div className="all-appointments-container">
                <Box className="wrapper">
                    <AppBar
                        position="sticky"
                        style={{ backgroundColor: "white", boxShadow: "none"}}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="secondary"
                            textColor="secondary"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                        >
                            <Tab label="Normal Appointments" sx={{ fontWeight: "bolder" }} {...a11yProps(0)} />
                            <Tab label="Urgent Appointments" sx={{ fontWeight: "bolder" }} {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>

                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <NormalAppointmentsOfAllAppointments normalAppointmentInitialPageNumber={1}></NormalAppointmentsOfAllAppointments>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <UrgentAppointmentsOfAllAppointments urgentAppointmentInitialPageNumber={1}></UrgentAppointmentsOfAllAppointments>
                    </TabPanel>
                </Box>
            </div>
        </>
    );
};

export default AllAppointments;
