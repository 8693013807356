
import Header from "../../components/Header/Header"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, InputAdornment, TextField } from '@mui/material'
import "./settings.scss"
import { useNavigate } from 'react-router';

export default function Settings() {
    const navigate = useNavigate()
    return (<>
        <Header />
        <div className='settings-container'>
            <TextField
                id="outlined-basic"
                label="Change password"
                variant="outlined" 
                disabled
                InputProps={{
                    endAdornment: <InputAdornment position="end"><KeyboardArrowRightIcon /></InputAdornment>,
                    readOnly: true,
                  }}
                onClick={()=> navigate('/changepassword')}
            />
        </div>
    </>)
}