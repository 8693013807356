import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, InputAdornment, TextField } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UpdateIcon from '@mui/icons-material/Update';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import "./profile.scss"
import { useEducationContext } from '../../context/profile/educationContext';
import { useSnackbar } from 'notistack'



export default function AddEducation() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');
  const [newEducation, setNewEducation] = React.useState({ institute: "", degree: "", start_year: "", end_year: "" })
  const { institute, degree, start_year, end_year } = newEducation

  const [active, setActive] = React.useState({ save: true, update: false, id: '' })
  const { education, setEducation, addEducation, deleteEducation, updateEducation } = useEducationContext()
  const { enqueueSnackbar } = useSnackbar()



  const handleEducation = () => {
    if (institute == "" || degree == "" || start_year == "" || end_year == "") {
      enqueueSnackbar('Required all fields', { variant: 'warning' }, { autoHideDuration: 6000 })
    }

    else if (start_year.length !== 4) {
      enqueueSnackbar('Invalid start year', { variant: 'warning' }, { autoHideDuration: 6000 })
    }
    else if (end_year.length !== 4) {
      enqueueSnackbar('Invalid end year', { variant: 'warning' }, { autoHideDuration: 6000 })
    }
    else {
      const obj = {
        degree: degree,
        discipline: 'medical',
        start_year: start_year,
        end_year: end_year,
        position: '1',
        institute: institute,
      }

      addEducation(obj)
      setOpen(false)

      setNewEducation({ institute: '', degree: '', start_year: '', end_year: '' })
    }
  }



  const updateStateByNewEducation = () => {
    const id = active.id

    const obj = {

    }

    updateEducation(obj, id)
    setActive({ id: '', save: true, update: false })
  }

  const handleClose = () => {
    setNewEducation({ institute: '', degree: '', start_year: '', end_year: '' })
    setOpen(false)
  }

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };



  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button
        onClick={handleClickOpen('body')}
        variant='contained'
        color='secondary'
      > <AddCircleOutlineIcon /> Add Education
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add Education</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ width: 500 }}
          >

            <TextField
              sx={{ display: 'block', mt: 2 }}
              id="outlined-required"
              label="Institution Name"
              value={newEducation.institute}
              defaultValue=""
              size='medium'
              fullWidth
              onChange={(e) => setNewEducation({ institute: e.target.value, degree, start_year, end_year })}
            />

            <TextField
              sx={{ display: 'block', mt: 3 }}
              id="outlined-required"
              label="Degree"
              value={newEducation.degree}
              defaultValue=""
              size='medium'
              fullWidth
              onChange={(e) => setNewEducation({ degree: e.target.value, institute, start_year, end_year })}
            />
            <TextField
              sx={{ display: 'block', mt: 3 }}
              id="outlined-required"
              label="Starting Year"
              value={newEducation.start_year}
              defaultValue=""
              size='medium'
              fullWidth
              onChange={(e) => setNewEducation({ start_year: e.target.value, end_year, institute, degree })}
            />

            <TextField
              sx={{ display: 'block', mt: 3, mb: 3 }}
              id="outlined-required"
              label="Ending Year"
              value={newEducation.end_year}
              defaultValue=""
              size='medium'
              fullWidth
              onChange={(e) => setNewEducation({ end_year: e.target.value, start_year, institute, degree })}
            />



          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" sx={{  '&:hover': { backgroundColor: 'red' } }}>Cancel</Button>
          {active.save && <Button variant="contained" color='secondary' onClick={handleEducation}>Save </Button>}


        </DialogActions>
        <Divider />
      </Dialog>
    </div>
  );
}