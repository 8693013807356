import {BASE_URL} from '../../utils/constants'
import axios from 'axios';
import { useSnackbar} from 'notistack'
import {
   createContext,
   useContext,
   useState,
   useEffect
} from "react";


const educationContext = createContext()

export const EducationContextProvider = ({ children }) => {
   let [education, setEducation] = useState([])
   let [singleEducation, setSingleEducation] = useState({doctor_education_id:"",institute: "",degree: "",start_year:"",end_year: ""})

   const loginInfo = JSON.parse(localStorage.getItem('logged'))
   const { enqueueSnackbar } = useSnackbar()


 
   useEffect(async()=>{
     if(loginInfo){
      const res = await fetch(BASE_URL+`/profile/${loginInfo.user_id}/educations`,{
         headers: {
          'Content-type':'application/json',
          'authorization':`${loginInfo.token}`
         }
      })
      const data = await res.json()
      setEducation(data.educations) 
     }   
},[])

 


   const addEducation = async(newEducation) => {
      const res = await fetch(BASE_URL+'/profile/doctor/add/education',{
         method: 'POST',
         headers: {
            'Content-type':'application/json',
            'authorization':`${loginInfo.token}`
         },
         body: JSON.stringify(newEducation)
       })
       const result = await res.json()
       if(result.status === true){
         setEducation(education => [...education, newEducation])
         enqueueSnackbar('Successfully added new education ', {variant: 'success'},{ autoHideDuration: 6000 })
       }
       else {
         enqueueSnackbar('Failed', {variant: 'error'} ,{ autoHideDuration: 6000 })
       }

   }

   const deleteEducation = async(doctor_education_id) => {
      const res = await fetch(BASE_URL+`/profile/doctor/${doctor_education_id}/delete/education`,{
         method: 'POST',
         headers: {
            'Content-type':'application/json',
            'authorization':`${loginInfo.token}`
         }
      })
   
      const filtered = education.filter(data => data.doctor_education_id!== doctor_education_id)
      setEducation(filtered)
      enqueueSnackbar('Successfully deleted ', { autoHideDuration: 5000 })
   }


   const updateEducation = async(doctor_education_id) => {
    
      const obj = {
         degree: singleEducation.degree,
         discipline: 'medical',
         institute: singleEducation.institute,
         start_year: (singleEducation.start_year).toString(),
         end_year: singleEducation.end_year,
         position: '1'
      }
      

      const res = await fetch(BASE_URL+`/profile/doctor/${doctor_education_id}/update/education`,{
         method: 'POST',
         headers: {
            'Content-type':'application/json',
            'authorization':`${loginInfo.token}`
         },
         body: JSON.stringify(obj)
       })
       const data = await res.json()
       if(data.status === true){
         let cloneEducation = [...education]
         const index = education.findIndex(data => data.doctor_education_id === doctor_education_id)
   
         cloneEducation.filter((data, i) => {
            if (i === index) {
                cloneEducation[index] = singleEducation
                setEducation(cloneEducation)
               
            }
         })
   
         enqueueSnackbar('Successfully updated the education', {variant: 'success'},{ autoHideDuration: 5000 })
       }
       else {
         enqueueSnackbar('Update failed', {variant: 'error'},{ autoHideDuration: 5000 })
       }
   }


    const getEducationById = (doctor_education_id) =>{          
      const index = education.findIndex(data => data.doctor_education_id === doctor_education_id)
      education.filter((data, i) => {
         if (i === index) {
            setSingleEducation({institute: data.institute, degree: data.degree, start_year: data.start_year,end_year: data.end_year})
         }
      })  
  } 


   return <educationContext.Provider
      value={{
         education,
         addEducation,
         setEducation,
         deleteEducation,
         updateEducation,
         singleEducation,
         getEducationById,
         setSingleEducation
      }}>
      {children}
   </educationContext.Provider>
}


export const useEducationContext = () => {
   const {
      education,
      setEducation,
      addEducation,
      deleteEducation,
      updateEducation,
      singleEducation,
      getEducationById,
      setSingleEducation
   } = useContext(educationContext)

   return {
      education,
      setEducation,
      addEducation,
      deleteEducation,
      updateEducation,
      singleEducation,
      getEducationById,
      setSingleEducation
   }
}