import AddBoxIcon from "@mui/icons-material/AddBox";
import SaveIcon from '@mui/icons-material/Save';
import {
    Box, Card,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import { purple } from "@mui/material/colors";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import React from "react";
import Header from '../../components/Header/Header';
import "./create_prescription.scss";
import DayMonthMedicine from "./day_months_medicine/DayMonthMedicine";
import DayMonthMedicineTextField from "./day_months_medicine_textfield/DayMonthMedicineTextField";
import TestName from "./test-names/TestName";

import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { BASE_URL } from "../../utils/constants";
import AdviceName from "./advice-name/AdviceName";

import { useSnackbar } from 'notistack';
import EPrescriptionDialog from "../../material/ePrescription/modal";


const CreatePrescription = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [value, setValue] = React.useState(dayjs(new Date()));

    const patientInfo = useLocation();
    const patientInformationForPrescriptionPage = patientInfo.state.item;

    const [complainValue, setComplainValue] = React.useState("");

    const [testName, setTestName] = React.useState([]);
    const [indexOfUpdateTestName, setIndexOfUpdateTestName] = React.useState(0);
    const [testValue, setTestValue] = React.useState("");
    const [isEditing, setIsEditing] = React.useState(false);


    const [adviceName, setAdviceName] = React.useState([]);
    const [indexOfUpdateAdviceName, setIndexOfUpdateAdviceName] = React.useState(0);
    const [adviceValue, setAdviceValue] = React.useState("");
    const [isEditingAdvice, setIsEditingAdvice] = React.useState(false);

    
    const [medicineInfos, setMedicineInfos] = React.useState([]);
    const [editableMedicineInfo, setEditableMedicineInfo] = React.useState({id: "", medicineInfo : {
        id: "",
        medicineInfo: {
            medicineName: "",
            days: "",
            month: "",
            comment: ""
        }
    }});

    // new state

    const [indexOfUpdateMedicineInfo, setIndexOfUpdateMedicineInfo] = React.useState(0);
    const [isEditingMedicineInfo, setEditingMedicineInfo] = React.useState(false);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    // const handleTestChange = (event) => {
    //     const name = event.target.value;
    //     if ((name?.trim()?.length || 0) > 0) {
    //         setTestName((tests) => [...tests, name]);
    //     }
    // };

    // -------------- Test name related Function ------------------

    const handleAddTestName = (event) => {
        if(testValue !== ""){
            setTestName((previousTestName) => {
                return [...previousTestName, testValue];
            });
        }
        setTestValue("");
    };

    const handleTestNameSaved = () => {
        testName[indexOfUpdateTestName] = testValue;
        setIsEditing(false);
        setTestValue("");
    }

    const handleEditTest = (id) => {
        setIndexOfUpdateTestName(id);
        console.log(id);
        if (typeof testName[id] == 'undefined') {
            console.log(testName[id]);
        } 
        setIsEditing(id);
        setTestValue(testName[id])
    };

    const handleDeleteTest = (id) => {
        const filteredTestNames = testName.filter((test, index) => index !== id);
        setTestName(filteredTestNames);
        //console.log(item);
    };

    // ------------- Advice Related Function -------------

    const handleAddAdviceName = (event) => {
        if(adviceValue !== ""){
            setAdviceName((previousAdviceName) => {
                return [...previousAdviceName, adviceValue];
            });
        }
        setAdviceValue("");
    };

    const handleDeleteAdvice = (id) => {
        const filteredAdviceNames = adviceName.filter((test, index) => index !== id);
        setAdviceName(filteredAdviceNames);
        //console.log(item);
    };

    const handleEditAdvice = (id) => {
        setIndexOfUpdateAdviceName(id);
        console.log(id);
        if (typeof adviceName[id] == 'undefined') {
            console.log(adviceName[id]);
        } 
        setIsEditingAdvice(id);
        setAdviceValue(adviceName[id])
    };

    const handleAdviceNameSaved = () => {
        adviceName[indexOfUpdateAdviceName] = adviceValue;
        setIsEditingAdvice(false);
        setAdviceValue("");
    }

    // -------------------- Medicine Info Function -------------
    const handleAddMedicineInfo = (medicineInfo) => {
        setMedicineInfos((previousMedicineInfos) => {
            return [...previousMedicineInfos, { id: uuidv4(), medicineInfo }];
        });
        //console.log(medicineInfos);
    };

    const handleRemoveMedicineInfo = (id) => {
        const filteredMedicineInfo = medicineInfos.filter(
            (medicineData) => medicineData.id !== id
        );
        setMedicineInfos(filteredMedicineInfo);
    };

    const handleEditableRemoveMedicineInfo = (id, updateIndex) => {
        const findMedicineInfo = medicineInfos.find(
            (medicineData) => medicineData.id === id
        );
        setEditableMedicineInfo(findMedicineInfo);
        setEditingMedicineInfo(true);
        setIndexOfUpdateMedicineInfo(updateIndex);
        console.log(findMedicineInfo);
    };

    const handleUpdateMedicineInfo = (formData) => {
        console.log(indexOfUpdateMedicineInfo);
        console.log(medicineInfos[indexOfUpdateMedicineInfo]);
        
        medicineInfos[indexOfUpdateMedicineInfo].medicineInfo.medicineName = formData.medicineName;
        medicineInfos[indexOfUpdateMedicineInfo].medicineInfo.days = formData.days;
        medicineInfos[indexOfUpdateMedicineInfo].medicineInfo.month = formData.month;
        medicineInfos[indexOfUpdateMedicineInfo].medicineInfo.comment = formData.comment;

        setMedicineInfos(medicineInfos);
        setEditingMedicineInfo(false);
    };

    const handleAllFormDataSubmit = async () => {
        // event.preventDefault();

        console.log(medicineInfos);

        const medicineArray = [];
        const medicineDate = {
            medicine_name: "",
            comment: "",
            day: ""
        }

        medicineInfos.map((item) => {
            medicineDate.medicine_name = item.medicineInfo.medicineName;
            medicineDate.comment = item.medicineInfo.comment;
            medicineDate.day = item.medicineInfo.days;
            medicineArray.push(medicineDate);
        })

        const prescriptionFormData = {
            chief_complain: complainValue,
            general_advice: adviceName.toString(),
            test: testName.toString(),
            medicines: medicineArray,
            patient_id: patientInfo.state.patient_id,
        };

        if(prescriptionFormData.chief_complain.trim().length === 0 || prescriptionFormData.medicines.length === 0) {
            enqueueSnackbar('You must have to provide complain and Medicine info',{variant:'warning'},{ anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }})
    
        }

        //chief_complain, general_advice, test, medicines, patient_id, followup_date
        await sendPrescriptionDataToServer(patientInfo.state.appointment_id, prescriptionFormData, patientInformationForPrescriptionPage); 
        
    };

    const sendPrescriptionDataToServer = async (id, prescriptionFormData, patientInformationForPrescriptionPage) => {

        const loginInfo = JSON.parse(localStorage.getItem('logged'));
        console.log(prescriptionFormData);
        
        try{
            if (loginInfo) {
                const prescriptionFormResponse = await axios.post(
                    BASE_URL + `/appointment/${id}/prescription`, prescriptionFormData, {
                        headers: {
                        'authorization': `${loginInfo.token}`
                        }
                    }
                );
    
                // dummyFunction(prescriptionFormData, patientInformationForPrescriptionPage);
                console.log(prescriptionFormResponse, patientInformationForPrescriptionPage);
                // navigate('/view_prescription');
                
            }
    
        }catch(error) {
            console.log(error);
        }
    }       

    // ---------------- Modal of View Prescription -------------------


    return (
        <>
            <Header></Header>
            <div className="patient-prescription-container">
                <div className="patient-prescription-info-header">
                    <Typography variant="h6" component="h2">
                        <b>Patient Type: </b> {patientInfo.state.is_followup === 0 ? "Follow Up" : "New"}
                    </Typography>
                    <Typography variant="h6" component="h2">
                        <b>Patient Name:</b> {patientInfo.state.patient_name}
                    </Typography>
                </div>
                <div className="create-prescription-fields">

                    <Card
                        variant="outlined"
                        sx={{
                            alignItems: "center",
                            p: 1,
                            border: "none"
                        }}
                    >
                        <TextField
                            id="outlined-basic"
                            label="Chief Complain(use, for multiple complain)"
                            variant="outlined"
                            onChange={(event)=>{setComplainValue(event.target.value)}}
                            fullWidth
                            // error={Boolean(complainValue.toString())}
                            // helperText={"complain must be provided"}
                        />
                    </Card>
                    
                    
                    {/* medicine-day-months-container */}
                    <DayMonthMedicineTextField
                        key={editableMedicineInfo.id}
                        editableMedicineInfo={editableMedicineInfo}
                        onHandleEditableRemoveMedicineInfo={handleEditableRemoveMedicineInfo}
                        onAddMedicineInfo={handleAddMedicineInfo}
                        onUpdateMedicineInfo={handleUpdateMedicineInfo}
                        isEditingMedicineInfo={isEditingMedicineInfo}
                    ></DayMonthMedicineTextField>
                    {/* editable medicine-day-months-container */}
                    {medicineInfos.map((medicineInfo, index) => (
                        <DayMonthMedicine
                            key={medicineInfo.id}
                            id={medicineInfo.id}
                            updateIndex={index}
                            medicineInfo={medicineInfo.medicineInfo}
                            onRemoveMedicineData={handleRemoveMedicineInfo}
                            onEditableRemoveMedicineInfo={handleEditableRemoveMedicineInfo}
                        ></DayMonthMedicine>
                    ))}

                    {/* ------- enter-test-name-container start ------*/}
                    <div className="enter-test-name-container">
                        <Card
                            variant="outlined"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                p: 1,
                                border: "none"
                            }}
                        >
                            <TextField
                                id="outlined-basic"
                                label="Enter test name"
                                variant="outlined"
                                onChange={(event)=>{setTestValue(event.target.value)}}
                                // onBlur={handleAddTestName}
                                fullWidth
                                value={testValue}
                            />
                            <Box sx={{ pr: 1 }}>
                                {isEditing === false? <IconButton
                                    aria-label="add"
                                    onClick={handleAddTestName}
                                >
                                    <AddBoxIcon
                                        sx={{
                                            height: 38,
                                            width: 38,
                                            color: purple[800],
                                        }}
                                        
                                    />
                                </IconButton>
                                :
                                <IconButton
                                    aria-label="save"
                                    color="error"
                                    onClick={handleTestNameSaved}
                                >
                                    <SaveIcon
                                        sx={{
                                            height: 38,
                                            width: 38,
                                        }}
                                    />
                                </IconButton>}
                            </Box>
                        </Card>
                    </div>

                    {/* ----------- test text field --------------*/}
                    {testName && testName.map((test, index) => {
                        return (
                            <TestName
                                key={index}
                                id={index}
                                test={test}
                                onAddTestName={handleAddTestName}
                                onEditTest={handleEditTest}
                                onRemoveTest={handleDeleteTest}
                            ></TestName>
                        );
                    })}

                    {/* ------- enter-advice-container start ------*/}
                    <div className="enter-advice-name-container">
                        <Card
                            variant="outlined"
                            sx={{  
                                display: "flex",
                                alignItems: "center",
                                p: 1,
                                border: "none"
                            }}
                        >

                            <TextField
                                id="outlined-basic"
                                label="Enter advice"
                                variant="outlined"
                                onChange={(event)=>{setAdviceValue(event.target.value)}}
                                // onBlur={handleAddTestName}
                                value={adviceValue}
                                fullWidth
                            />

                            <Box sx={{ pr: 1 }}>
                                {isEditingAdvice === false? <IconButton
                                    aria-label="add"
                                    onClick={handleAddAdviceName}
                                >
                                    <AddBoxIcon
                                        sx={{
                                            height: 38,
                                            width: 38,
                                            color: purple[800],
                                        }}
                                        
                                    />
                                </IconButton>
                                :
                                <IconButton
                                    aria-label="save"
                                    color="error"
                                    onClick={handleAdviceNameSaved}
                                >
                                    <SaveIcon
                                        sx={{
                                            height: 38,
                                            width: 38,
                                        }}
                                    />
                                </IconButton>}
                            </Box>
                        </Card>
                    </div>

                    {/* ------------ editable advice test field---------------*/}
                    {adviceName && adviceName.map((advice, index) => {
                        return (
                            <AdviceName
                                key={index}
                                id={index}
                                advice={advice}
                                // onAddTestName={handleAddTestName}
                                onEditAdvice={handleEditAdvice}
                                onRemoveAdvice={handleDeleteAdvice}
                            ></AdviceName>
                        );
                    })}

                    {/* Followup Date started */}
                    <div className="enter-advice-container">
                        <Card
                            variant="outlined"
                            sx={{ display: "flex", alignItems: "center", border: "none" }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        p: 1,
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        Followup Date
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ p: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        label="Select Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={value}
                                        onChange={handleChange}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Card>
                    </div>
                    {/* Button container*/}
                    <div className="button-container">
                        <EPrescriptionDialog handleFormDataSubmit={handleAllFormDataSubmit} appointmentID={patientInformationForPrescriptionPage.appointment_id}></EPrescriptionDialog>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreatePrescription;
