import AddBoxIcon from "@mui/icons-material/AddBox";
import SaveIcon from '@mui/icons-material/Save';
import { Box, Card, IconButton, TextField } from "@mui/material";
import { purple } from "@mui/material/colors";
import React from "react";
import { useForm } from "react-hook-form";

const DayMonthMedicineTextField = (props) => {
    const {editableMedicineInfo, isEditingMedicineInfo} = props;
    const medicineInfo = editableMedicineInfo.medicineInfo;
    
    const {
        register,
        handleSubmit,
        watch,
        reset, 
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        props.onAddMedicineInfo(data);
        reset({
            medicineName: '',
            days: '', 
            month: '', 
            comment: ''
        });
        // console.log(data);
    };

    const onUpdate = (data) => {
        props.onUpdateMedicineInfo(data);
        reset({
            medicineName: '',
            days: '', 
            month: '', 
            comment: ''
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Card variant="outlined" sx={{ display: "flex", border: "none" }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",

                                pt: 1,
                                pl: 1,
                                pr: 1,
                            }}
                        >
                            <TextField
                                id="outlined-basic"
                                label="Enter Medicine"
                                variant="outlined"
                                sx={{
                                    pr: 1,
                                }}
                                defaultValue={medicineInfo.medicineName}
                                {...register("medicineName", {
                                    required: "medicine name is required",
                                    minLength: {value: 2, message:"must be more than 2 characters"},
                                })}
                                error={Boolean(errors.medicineName)}
                                helperText={errors.medicineName?.message}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Days"
                                variant="outlined"
                                sx={{
                                    pr: 1,
                                }}
                                defaultValue={medicineInfo.days}
                                {...register("days", {
                                    required: "day is required",
                                    min: {value: 0, message:"day must be positive"},
                                    max: {value: 99, message:"day must be less than 100"},
                                    pattern: {
                                        value: /^\d*[.]?\d*$/,
                                        message: 'Input must have to be number',
                                    },
                                })}
                                error={Boolean(errors.days)}
                                helperText={errors.days?.message}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Months"
                                variant="outlined"
                                defaultValue={medicineInfo.month}
                                {...register("month", {
                                    required: "month is required",
                                    min: {value: 0, message:"month must be positive"},
                                    max: {value: 31, message:"month must be less than 32"},
                                    pattern: {
                                        value: /^\d*[.]?\d*$/,
                                        message: 'Input must have to be number',
                                    },
                                })}
                                error={Boolean(errors.month)}
                                helperText={errors.month?.message}
                            />
                        </Box>
                        <Box
                            sx={{
                                p: 1,
                            }}
                        >
                            <TextField
                                id="outlined-basic"
                                label="comments"
                                variant="outlined"
                                defaultValue={medicineInfo.comment}
                                {...register("comment", {
                                    required: "comment is required",
                                    minLength: {value: 3, message:"comment must be more than 3 characters"},
                                })}
                                error={Boolean(errors.comment)}
                                helperText={errors.comment?.message}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            pr: 1,
                        }}
                    >
                        {
                            isEditingMedicineInfo ?                                 <IconButton
                                aria-label="save"
                                color="error"
                                onClick={handleSubmit(onUpdate)}
                            >
                                <SaveIcon
                                    sx={{
                                        height: 50,
                                        width: 50,
                                    }}
                                />
                            </IconButton>
                            :
                            <IconButton
                                type="submit"
                                aria-label="add-item"
                            >
                                <AddBoxIcon
                                    sx={{
                                        height: 50,
                                        width: 50,
                                        color: purple[800],
                                    }}
                                />
                            </IconButton>
                        }


                    </Box>
                </Card>
            </form>
        </div>
    );
};

export default DayMonthMedicineTextField;
