import axios from "axios";
import { createContext, useContext, useState } from "react";
import { BASE_URL } from "../../utils/constants";
import { useAuth } from "../authContext";

const todaysNormalAppointmentsContext = createContext();

export const TodaysNormalAppointmentsContextProvider = (({ children }) => {

    const [todaysNormalAppointments, setTodaysNormalAppointments] = useState([]);
    const auth = useAuth();
    const loginInfo = JSON.parse(localStorage.getItem('logged'));


    const todaysNormalAppointmentsInfoFunction = async (pageNumberTodaysNormal) => {
        try {
            const response = await axios.get(BASE_URL+`/appointment/list/doctor?page=${pageNumberTodaysNormal}&limit=10&all=0&type=normal`, {
                headers: {
                  'authorization': `${loginInfo.token}`
                }
            });
            if (pageNumberTodaysNormal === 1) {
                setTodaysNormalAppointments([]);
                setTodaysNormalAppointments(response.data.appointments);
            } else {
                setTodaysNormalAppointments((previousData) => [...previousData, ...response.data.appointments]);
            }      
        } catch (error) {
            console.error(error);
        }
    }

    return  <todaysNormalAppointmentsContext.Provider value = {{todaysNormalAppointments, todaysNormalAppointmentsInfoFunction}}>{children}</todaysNormalAppointmentsContext.Provider>
})

export const useTodaysNormalAppointmentsContext = () => {
    const {
        todaysNormalAppointments,
        todaysNormalAppointmentsInfoFunction,
        
    } = useContext(todaysNormalAppointmentsContext)
  
    return {
        todaysNormalAppointments,
        todaysNormalAppointmentsInfoFunction,
    }
}