import * as React from 'react';
import "./registration.scss"
import { useNavigate } from 'react-router-dom'


import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';


import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';





import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';

import { useForm } from "react-hook-form";




export default function Registeration() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [dob, setDob] = React.useState(dayjs('2023-04-07'));
  const { register, handleSubmit, watch, formState: { errors } } = useForm();


  const navigate = useNavigate()

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const onSubmit = data => {
    const date = dob.$y+"-"+dob.$M+"-"+dob.$D
  
  }



  return (
    <div>
      <div className="register-container">
        <form className="form" onSubmit={handleSubmit(onSubmit)}>

          <h2 >Register</h2>
          <h4>To register set name and password here</h4>

          <FormControl fullWidth sx={{mt:3}}>
          <TextField
              
              id="filled-multiline-flexible"
              label="Full Name"
              multiline
              maxRows={4}
              variant="filled"
              size='small'
              {...register("fullName", { required: true })}
          />
          </FormControl>
          {errors.fullName && <span style={{ textAlign: 'left', color: 'red' }}>Required user name field!</span>}


        <FormControl fullWidth sx={{mt:3}}>
            <InputLabel id="demo-simple-select-label">Please select department</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Please select department"
              
            >
              
              <MenuItem value={10}>Cardiology</MenuItem>
              <MenuItem value={20}>Pediatric</MenuItem>
              <MenuItem value={30}>Colorectal Surgary (Piles)</MenuItem>
              <MenuItem value={30}>Dermatologoy</MenuItem>
              <MenuItem value={30}>Dentistry</MenuItem>
              <MenuItem value={30}>Diabetology</MenuItem>
              <MenuItem value={30}>E.N.T</MenuItem>
              <MenuItem value={30}>Gastroenerology</MenuItem>
              <MenuItem value={30}>General Physician</MenuItem>
              <MenuItem value={30}>Gynecology</MenuItem>
              <MenuItem value={30}>Hematology</MenuItem>
              <MenuItem value={30}>Nephrology</MenuItem>
              <MenuItem value={30}>Medicine</MenuItem>
              <MenuItem value={30}>Neuro Medicine</MenuItem>
              <MenuItem value={30}>Nutritionest</MenuItem>
              <MenuItem value={30}>Oncolory</MenuItem>
              <MenuItem value={30}>Orthopedics</MenuItem>
              <MenuItem value={30}>Plastic Surgary</MenuItem>
              <MenuItem value={30}>Urology</MenuItem>
              <MenuItem value={30}>Opthalmology</MenuItem>
              <MenuItem value={30}>Rheumatology</MenuItem>
              <MenuItem value={30}>Endocrinology</MenuItem>
              <MenuItem value={30}>Pulmonology</MenuItem>
              <MenuItem value={30}>Neurosurgery</MenuItem>
              <MenuItem value={30}>Psychologist</MenuItem>
            </Select>
        </FormControl>

        <FormControl fullWidth sx={{mt:3}}>
          <TextField
                id="filled-multiline-flexible"
                label="Degree"
                multiline
                maxRows={4}
                variant="filled"
                size='small'
                {...register("degree", { required: true })}
            />
        </FormControl>
        {errors.degree && <span style={{ textAlign: 'left', color: 'red' }}>Required degree field!</span>}

        <FormControl fullWidth sx={{mt:3}}>
          <TextField
              id="filled-multiline-flexible"
              label="Work Place"
              multiline
              maxRows={4}
              variant="filled"
              size='small'
              {...register("workplace", { required: true })}
          />
       </FormControl>
       {errors.workplace && <span style={{ textAlign: 'left', color: 'red' }}>Required work place field!</span>}



       <FormControl fullWidth sx={{mt:3}}>
        <TextField
              id="filled-multiline-flexible"
              label="BMDC Number"
              multiline
              maxRows={4}
              variant="filled"
              size='small'
              {...register("bmdcnumber", { required: true })}
          />
      </FormControl>
      {errors.bmdcnumber && <span style={{ textAlign: 'left', color: 'red' }}>Required bmdc field!</span>}
          

          <FormLabel id="demo-row-radio-buttons-group-label" style={{ textAlign: 'left', marginTop: '20px' }}>Gender</FormLabel>
          <RadioGroup 
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel  {...register("gender", { required: true })} value="female" control={<Radio color='secondary'/>} label="Female" />
            <FormControlLabel  {...register("gender")} value="male" control={<Radio color='secondary' />} label="Male" />
           

          </RadioGroup>
          {errors.gender && <span style={{ textAlign: 'left', color: 'red' }}>Select gender</span>}

          <FormControl fullWidth sx={{mt:3}}>
            <TextField
                id="filled-multiline-flexible"
                label="Phone Number"
                multiline
                disabled
                maxRows={4}
                variant="filled"
                size='small'
            />
         </FormControl>


          <FormControl sx={{ mt: 4}} variant="filled">
            <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
            <FilledInput
              size='small'
              id="filled-adornment-password"
              type={showPassword ? 'text' : 'password'}
              {...register("password", { required: true,minLength:6 })}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          {errors.password && <span style={{ textAlign: 'left', color: 'red' }}>Required password!</span>}
        

          <Button 
            sx={{
              mt: 5,
              width: '64ch',
              backgroundColor: '#8010AB',
              "&:hover": {
                backgroundColor: '#8010AB'
              }
            }}
            variant="contained"
            type='submit'
          >
            REGISTER
          </Button>

        </form>

      </div>

    </div>
  )
}
