import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UpdateIcon from '@mui/icons-material/Update';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSnackbar } from 'notistack'
import { useExperienceContext } from '../../context/profile/experienceContext';

export default function AddExperience() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');
  const [workplace, setWorkplace] = React.useState('')
  const [dept, setDept] = React.useState('')
  const [designation, setDesignation] = React.useState('')
  const [startingYear, setStartingYear] = React.useState('')
  const [endingYear, setEndingYear] = React.useState('')
  const [present, setPresent] = React.useState(true)
  const { enqueueSnackbar } = useSnackbar()


  const {
    experience,
    addExperience,
    deleteExperience,
    updateExperience
  } = useExperienceContext()



  const handleExperience = () => {
    if (workplace === "" || dept === "" || designation === "") {
      enqueueSnackbar('Required all fields', { variant: 'warning' }, { autoHideDuration: 6000 })
    }
    else if (startingYear.length !== 4) {
      enqueueSnackbar('Invalid start year', { variant: 'warning' }, { autoHideDuration: 6000 })
    }
    else if (endingYear.length !== 4) {
      enqueueSnackbar('Invalid end year', { variant: 'warning' }, { autoHideDuration: 6000 })
    }
    else {
      const newExperience = {
        workPlace: workplace,
        department: dept,
        designation: designation,
        start_year: startingYear,
        end_year: endingYear,
        position: '0'
      }

      addExperience(newExperience)

      setWorkplace('')
      setDept('')
      setDesignation('')
      setStartingYear('')
      setEndingYear('')
      setOpen(false);
    }
  }

  const handleYear = () => {
    if (present) {
      setEndingYear('Present')
      setPresent(false)
    }
    else {
      setEndingYear("")
      setPresent(true)
    }
  }


  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);





  return (
    <div>
      <Button variant='contained'
      color='secondary'
        onClick={handleClickOpen('body')}
      >
        <AddCircleOutlineIcon /> Add Experience
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add Experience</DialogTitle>
        <DialogContent dividers={scroll === 'body'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ width: 500 }}
          >

            <TextField
              sx={{ display: 'block' }}
              id="outlined-required"
              label="Workplace Name"
              defaultValue=""
              size='medium'
              fullWidth
              value={workplace}
              onChange={(e) => setWorkplace(e.target.value)}
            />

            <FormControl fullWidth sx={{ mt: 3 }}>
              <InputLabel id="demo-simple-select-label">Please select department</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Please select department"
                onChange={(e) => setDept(e.target.value)}
                value={dept}

              >

                <MenuItem value="cardiology">Cardiology</MenuItem>
                <MenuItem value="pediatric">Pediatric</MenuItem>
                <MenuItem value="colorectal-sergary(piles)">Colorectal Surgary (Piles)</MenuItem>
                <MenuItem value="dermatology">Dermatology</MenuItem>
                <MenuItem value="dentistry">Dentistry</MenuItem>
                <MenuItem value="diabetology">Diabetology</MenuItem>
                <MenuItem value="ent">E.N.T</MenuItem>
                <MenuItem value="gastroenerology">Gastroenerology</MenuItem>
                <MenuItem value="general-physician">General Physician</MenuItem>
                <MenuItem value="gynecology">Gynecology</MenuItem>
                <MenuItem value="hematology">Hematology</MenuItem>
                <MenuItem value="nephrology">Nephrology</MenuItem>
                <MenuItem value="medicine">Medicine</MenuItem>
                <MenuItem value="neuro medicine">Neuro Medicine</MenuItem>
                <MenuItem value="nutritionist">Nutritionist</MenuItem>
                <MenuItem value="oncology">Oncology</MenuItem>
                <MenuItem value="orthopedics">Orthopedics</MenuItem>
                <MenuItem value="plastic surgary">Plastic Surgary</MenuItem>
                <MenuItem value="urology">Urology</MenuItem>
                <MenuItem value="opthalmology">Opthalmology</MenuItem>
                <MenuItem value="rheumatology">Rheumatology</MenuItem>
                <MenuItem value="endocrinology">Endocrinology</MenuItem>
                <MenuItem value="pulmonology">Pulmonology</MenuItem>
                <MenuItem value="neurosurgary">Neurosurgery</MenuItem>
                <MenuItem value="psychologist">Psychologist</MenuItem>
              </Select>
            </FormControl>

            <TextField
              sx={{ display: 'block', mt: 3 }}
              id="outlined-required"
              label="Designation"
              defaultValue=""
              size='medium'
              fullWidth
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />

            <TextField
              sx={{ display: 'block', mt: 3 }}
              id="outlined-required"
              label="Starting Year"
              defaultValue=""
              size='medium'
              fullWidth
              value={startingYear}
              onChange={(e) => setStartingYear(e.target.value)}
            />
            <TextField
              sx={{ display: 'block', mt: 3 }}
              id="outlined-required"
              label="Ending Year"
              defaultValue=""
              size='medium'
              fullWidth
              value={endingYear}
              onChange={(e) => setEndingYear(e.target.value)}
            />

            <FormGroup>
              <FormControlLabel control={<Checkbox color='secondary' onClick={handleYear} />} label="Present" />
            </FormGroup>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" sx={{  '&:hover': { backgroundColor: 'red' } }}>Cancel</Button>
          <Button onClick={handleExperience} color='secondary' variant="contained">Save</Button>
        </DialogActions>
        <Divider />
      </Dialog>
    </div>
  );
}