import { BASE_URL } from '../../utils/constants'
import {
    useContext,
    createContext,
    useEffect,
    useState
} from "react";

const scheduleContext = createContext()


export const ScheduleContextProvider = ({ children }) => {
    const [schedule, setSchedule] = useState([])
    const [tempSchedule, setTempSchedule] = useState([])
    const loginInfo = JSON.parse(localStorage.getItem("logged"))




    useEffect(async () => {
      if(loginInfo){
        const res = await fetch(BASE_URL +`/profile/doctor/schedules/${loginInfo.user_id}`, {
            headers: {
                "Content-type": "application/json",
                "authorization": `${loginInfo.token}`
            }
        })
        const result = await res.json()
        setSchedule(result.data)
        setTempSchedule(result.data)
      }
    }, [])


  const addSchedule = async() =>{
       const obj = {
          interval: 5,
          appointment_type: "",
          data: {
            1:{
                startTime: new Date(tempSchedule[0].start_time).getTime(),
                endTime  : new Date(tempSchedule[0].end_time).getTime()
            },
            2:{
                startTime: new Date(tempSchedule[1].start_time).getTime(),
                endTime  : new Date(tempSchedule[1].end_time).getTime()
            },
            3:{
                startTime: new Date(tempSchedule[2].start_time).getTime(),
                endTime  : new Date(tempSchedule[2].end_time).getTime()
            },
            4:{
                startTime: new Date(tempSchedule[3].start_time).getTime(),
                endTime  : new Date(tempSchedule[3].end_time).getTime()
            },
            5:{
                startTime: new Date(tempSchedule[4].start_time).getTime(),
                endTime  : new Date(tempSchedule[4].end_time).getTime()
            },
            6:{
                startTime: new Date(tempSchedule[5].start_time).getTime(),
                endTime  : new Date(tempSchedule[5].end_time).getTime()
            },
            0:{
                startTime: new Date(tempSchedule[6].start_time).getTime(),
                endTime  : new Date(tempSchedule[6].end_time).getTime()
            }
          }
       }
   console.log(new Date(tempSchedule[0].start_time.toLocaleTimeString()))
   const res = await fetch(BASE_URL+"/profile/doctor/add/schedule",{
             method:"POST",
             headers: {
                "Content-type":"application/json",
                "authorization":`${loginInfo.token}`
             },
             body: JSON.stringify(obj)
   })
     const result = await res.json()
   //  console.log(result)
   
  }


    return <scheduleContext.Provider value={{ schedule, tempSchedule, setTempSchedule ,addSchedule}}>{children}</scheduleContext.Provider>
}

export const useScheduleContext = () => {
    const {
        schedule,
        tempSchedule,
        setTempSchedule,
        addSchedule
    } = useContext(scheduleContext)

    return {
        schedule,
        tempSchedule,
        setTempSchedule,
        addSchedule
    }
}

