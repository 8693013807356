import React from 'react'
import './education.scss'
import Header from '../../../components/Header/Header'
import AddEducation from "../../../material/Profile/AddEducation"
import { useEducationContext } from '../../../context/profile/educationContext'
import { Button } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import UpdateIcon from '@mui/icons-material/Update';
import Swal from 'sweetalert2'
import UpdateEducation from '../../../material/Profile/UpdateEducation'
import { useSnackbar,SnackbarProvider} from 'notistack'



export default function Education() {
  
    const { 
        education,
        setEducation,
        addEducation,
        deleteEducation,
        updateEducation ,
        getEducationById,
        setSingleEducation
     } = useEducationContext()

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            customClass:'educationswal',
          }).then((result) => {
            if (result.isConfirmed) {
            deleteEducation(id)
            }
          })         
    }

  const handleSingleEducation = (data) => {
     setSingleEducation({doctor_education_id: data.doctor_education_id,institute: data.institute,degree: data.degree,start_year: data.start_year,end_year: data.end_year})
  }

    return (
        <>
           <Header />
            <div className='education-container'>
                <div className="wrapper" >
                    <AddEducation />
                     <div style={{overflowX:'auto'}}>
                     <table>
                        <tr>
                            <th>Institution Name</th>
                            <th>Degree</th>
                            <th>Starting Year</th>
                            <th>Ending Year</th>
                            <th>Actions</th>
                        </tr>
                        {education.map((data,index) => (
                            <tr key={index}>
                                <td>{data.institute}</td>
                                <td>{data.degree}</td>
                                <td>{data.start_year}</td>
                                <td>{data.end_year}</td>
                                <td className='action'>       
                                
                                 <UpdateEducation value={{handleSingleEducation,data}}/>
                                 <Button variant="outlined" color='error' onClick={() => handleDelete(data.doctor_education_id)}><DeleteOutlineIcon /></Button>
                                </td>
                            </tr>
                        ))}

                    </table>
                     </div>
                </div>
            </div>
        </>
    )
}
