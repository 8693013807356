import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CardItem from '../../components/CardItem/CardItem';
import Header from '../../components/Header/Header';
import { useViewDetailsContext } from '../../context/viewDetails/viewDetailsContext';
import './view_details.scss';

const ViewDetails = () => {

    const {viewDetailsInfoOfPatient, viewDetailsInfoFunction} = useViewDetailsContext();
    const patientInfo = useLocation();
    const patientInformation = patientInfo.state.item;

    useEffect(() => {
        viewDetailsInfoFunction(patientInformation.patient_id, patientInformation.appointment_id);
        // setLoading(false);
    }, []);

    // console.log(viewDetailsInfoOfPatient);

    return (
        

        <>
            <Header></Header>
            <div className='view-details-container'>
                <div className='view-details-card-container' id="top-card-view">
                    <CardItem key={patientInformation.patient_id} item={patientInformation}></CardItem>
                    <div className='text-section-container'>
                        <h5>Previous appointments of the patient:</h5>
                    </div>
                </div>
                <div className='previous-appointment-container'>

                    {
                        viewDetailsInfoOfPatient && viewDetailsInfoOfPatient.map((item, index) => {
                            return <CardItem key={index} item={item}></CardItem>
                        })
                    }

                </div>
            </div>
        </>
    );
};

export default ViewDetails;