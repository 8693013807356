import { BASE_URL } from "../../utils/constants";
import { useSnackbar } from 'notistack'
import {
    createContext,
    useContext,
    useState,
    useEffect
} from "react";

const experienceContext = createContext()

export const ExperienceContextProvider = ({ children }) => {
    const [singleExperience, setSingleExperience] = useState({ work_place: '', department: '', designation: '', start_year: '', end_year: '' })

    const [experience, setExperience] = useState([])
    const loginInfo = JSON.parse(localStorage.getItem('logged'))
    const { enqueueSnackbar } = useSnackbar()


    useEffect(async () => {
       if(loginInfo){
        const res = await fetch(BASE_URL + `/profile/${loginInfo.user_id}/experiences`, {
            headers: {
                'Content-type': 'application/json',
                'authorization': `${loginInfo.token}`
            }
        })
        const result = await res.json()
        setExperience(result.experiences)
       }

    }, [])



    const addExperience = async (newExperience) => {
        const res = await fetch(BASE_URL + '/profile/doctor/add/experience', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'authorization': `${loginInfo.token}`
            },
            body: JSON.stringify(newExperience)
        })
        const result = await res.json()
        if (result.status === true) {
            const obj = {
                work_place: newExperience.workPlace,
                department: newExperience.department,
                designation: newExperience.designation,
                start_year: newExperience.start_year,
                end_year: newExperience.end_year
            }
            setExperience(experience => [...experience, obj])
            enqueueSnackbar('Successfully added new Experience ', { variant: 'success' }, { autoHideDuration: 6000 })
        }
        else {
            enqueueSnackbar('Failed', { variant: 'error' }, { autoHideDuration: 6000 })
        }
    }


    const deleteExperience = (doctor_experience_id) => {
        fetch(BASE_URL + `/profile/doctor/${doctor_experience_id}/delete/experience`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'authorization': `${loginInfo.token}`
            }
        })
        const filtered = experience.filter(data => data.doctor_experience_id !== doctor_experience_id)
        setExperience(filtered)
    }

    const updateExperience = async (doctor_experience_id) => {
        const obj = {
            workPlace: singleExperience.work_place,
            department: singleExperience.department,
            designation: singleExperience.designation,
            start_year: (singleExperience.start_year).toString(),
            end_year: singleExperience.end_year,
            position: '0'
        }
        const res = await fetch(BASE_URL + `/profile/doctor/${doctor_experience_id}/update/experience`, {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
                'authorization': `${loginInfo.token}`
            },
            body: JSON.stringify(obj)
        })
        const result = await res.json()
        if (result.status === true) {
            let cloneExperience = [...experience]
            const index = experience.findIndex(data => data.doctor_experience_id === doctor_experience_id)

            cloneExperience.filter((data, i) => {
                if (i === index) {
                    cloneExperience[index] = singleExperience
                    setExperience(cloneExperience)

                }
            })
            enqueueSnackbar('Successfully updated', { variant: 'success' }, { autoHideDuration: 6000 })
        }
        else {
            enqueueSnackbar('failed', { variant: 'error' }, { autoHideDuration: 6000 })
        }
    }


    return <experienceContext.Provider
        value={{
            experience,
            addExperience,
            deleteExperience,
            updateExperience,
            singleExperience,
            setSingleExperience
        }}>
        {children}
    </experienceContext.Provider>
}


export const useExperienceContext = () => {
    const {
        experience,
        addExperience,
        deleteExperience,
        updateExperience,
        singleExperience,
        setSingleExperience
    } = useContext(experienceContext)


    return {
        experience,
        addExperience,
        deleteExperience,
        updateExperience,
        singleExperience,
        setSingleExperience
    }
}