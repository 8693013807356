import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Avatar,
    Button,
    Checkbox,
    FilledInput,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Paper,
    TextField,
} from "@mui/material";
import { purple } from "@mui/material/colors";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./login.scss";
import { useAuth } from "../../context/authContext";
import { redirect } from "react-router-dom";

import { SnackbarProvider, useSnackbar } from 'notistack';


const Login = () => {
    const avatarStyle = { backgroundColor: "#8010ab" };
    const [showPassword, setShowPassword] = React.useState(false);
    const navigate = useNavigate()
    const auth = useAuth()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();


    const onSubmit = async (data) => {
        const logged = await auth.login(data)
        if (logged) {

            navigate('/')
        }
        else {
            enqueueSnackbar('Unaluthorized user!', { variant: 'warning' }, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            })


        }

    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    return (
        <div>
            <SnackbarProvider />
            <div className="login-container">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid>
                        <Paper elevation={10} className="paper">
                            <Grid align="center">
                                <Avatar style={avatarStyle}>P</Avatar>
                                <h2>Login</h2>
                            </Grid>

                            <TextField
                                label="Mobile Number"
                                id="filled-start-adornment"
                                {...register("phoneNumber", { required: true })}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            +88
                                        </InputAdornment>
                                    ),
                                }}
                                variant="filled"
                            />

                            {errors.phoneNumber && (
                                <span
                                    style={{ textAlign: "left", color: "red" }}
                                >
                                    Required phone number!
                                </span>
                            )}

                            <FormControl
                                fullWidth
                                sx={{ mt: 3 }}
                                variant="filled"
                            >
                                <InputLabel htmlFor="filled-adornment-password">
                                    Password
                                </InputLabel>
                                <FilledInput
                                    id="filled-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    {...register("password", {
                                        required: true,
                                        minLength: 6,
                                    })}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            {errors.password && (
                                <span
                                    style={{ textAlign: "left", color: "red" }}
                                >
                                    Required password!
                                </span>
                            )}

                            <FormControlLabel
                                sx={{ mt: 2, mb: 2 }}
                                control={
                                    <Checkbox
                                        name="checkedB"
                                        sx={{
                                            color: purple[800],

                                            "&.Mui-checked": {
                                                color: purple[600],
                                            },
                                        }}
                                    />
                                }
                                label="Remember me"
                            />
                            <Button
                                className="login-button"
                                type="submit"
                                variant="contained"
                                fullWidth

                            >
                                Sign in
                            </Button>
                        </Paper>
                    </Grid>
                </form>
            </div>
        </div>
    );
};

export default Login;
