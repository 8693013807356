import React from 'react'
import "./notFound.scss"

export default function NotFound() {
  return (
    <div className='notFound'>
        <h1 className='code'>404!</h1>
        <h1>Page Not Found!!</h1>
    </div>
  )
}
