import {
    createContext
} from 'react'


import { AccountContextProvider } from './accountContext'
import { NormalAppointmentsContextProvider } from './allAppointment/normalAppointmentsContext'
import { UrgentAppointmentsContextProvider } from './allAppointment/urgentAppointmentsContext'
import { AuthContextProvider } from './authContext'
import { NotificationContextProvider } from './notification/notificationContext'
import { EducationContextProvider } from './profile/educationContext'
import { ExperienceContextProvider } from './profile/experienceContext'
import { ProfileContextProvider } from './profile/profileContext'
import { TodaysNormalAppointmentsContextProvider } from './todaysAppointment/todaysNormalAppointmentContext'
import { TodaysUrgentAppointmentsContextProvider } from './todaysAppointment/todaysUrgentAppointmentContext'
import { ViewDetailsContextProvider } from './viewDetails/viewDetailsContext'
import { ScheduleContextProvider } from './profile/scheduleContext'
import { BankInfoContextProvider } from './profile/bankInfoContext'

const appContext = createContext()


export const AppContextProvider = ({ children }) => {

    return <appContext.Provider value="">
        <AuthContextProvider>
            <EducationContextProvider>
                <ExperienceContextProvider>
                    <AccountContextProvider>
                        <NormalAppointmentsContextProvider>
                            <UrgentAppointmentsContextProvider>
                                <TodaysNormalAppointmentsContextProvider>
                                    <TodaysUrgentAppointmentsContextProvider>
                                        <NotificationContextProvider>
                                            <ViewDetailsContextProvider>
                                                <ProfileContextProvider>
                                                    <ScheduleContextProvider>
                                                      <BankInfoContextProvider>
                                                      {children}
                                                      </BankInfoContextProvider>
                                                    </ScheduleContextProvider>
                                                </ProfileContextProvider>
                                            </ViewDetailsContextProvider>
                                        </NotificationContextProvider>
                                    </TodaysUrgentAppointmentsContextProvider>
                                </TodaysNormalAppointmentsContextProvider>
                            </UrgentAppointmentsContextProvider>
                        </NormalAppointmentsContextProvider>
                    </AccountContextProvider>
                </ExperienceContextProvider>
            </EducationContextProvider>
        </AuthContextProvider>
    </appContext.Provider>
}