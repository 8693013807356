import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Badge } from '@mui/material';
import Menu from '@mui/material/Menu';
import * as React from 'react';
import { useNotificationsContext } from '../../context/notification/notificationContext';
import "./notifications.scss";

const ITEM_HEIGHT = 135;

export default function Notifications() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const {notifications, notificationsUnseenCount, notificationsFetchFunction, notificationsUnseenCountFetchFunction} = useNotificationsContext();

  React.useEffect(() => {
    notificationsUnseenCountFetchFunction();
  }, [])



  const notificationTimeCalculation = (timeStampOfCreating) => {

    let dateTimeNow = new Date(); //"now"
    let timeOfCreating = new Date("2023-02-01T14:12:00.000Z");  

    let diff = Math.abs(dateTimeNow - timeOfCreating);
    let time = Math.floor((Math.floor(parseInt((diff) / 3600)/60)));
    let showTime;
    
    if(Math.floor(time / 525600) > 0) {
        time = Math.floor(time / 525600);
        showTime = `${time} years ago`;
    } else if (Math.floor( time / 43800) > 0) {
      if(Math.floor( time / 43800) === 1){
        time = Math.floor(time / 43800);
        showTime = `${time} month ago`;
      } else {
        time = Math.floor(time / 43800);
        showTime = `${time} months ago`;
      }
    } else if(Math.floor( time / 1440) > 0) {
      if(Math.floor( time / 1440) === 1) {
        time = Math.floor(time / 1440);
        showTime = `${time} day ago`;
      }else {
        time = Math.floor(time / 1440);
        showTime = `${time} days ago`;
      }
    } else if (Math.floor( time / 60) > 0) {
      if(Math.floor(time / 60) === 1){
        time = Math.floor(time / 60);
        showTime = `${time} hour ago`;
      } else {
        time = Math.floor(time / 60);
        showTime = `${time} hours ago`;
      }
    } else {
      showTime = `${time} minutes ago`;
    }
    return showTime;
  }


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    notificationsFetchFunction();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {/* <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton> */}

      <Badge
        onClick={handleClick}
        badgeContent={notificationsUnseenCount}
        color="secondary">
        <NotificationsActiveIcon className='icon' />
      </Badge>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '40ch',
            marginTop:'2ch'
          },
        }}
      >
        {notifications && notifications.map((notification) => (
              <div className='notification'>
                  <p>{notification.content}</p>
                  <p className='time'>{notificationTimeCalculation(notification.createdAt)}</p>
              </div>
        ))}
      </Menu>
    </div>
  );
}

