import { BASE_URL } from "../../utils/constants";
import { SnackbarProvider, useSnackbar } from 'notistack'
import {
  createContext,
  useContext,
  useEffect,
  useState
} from "react";

const bankInfoContext = createContext()

export const BankInfoContextProvider = ({ children }) => {
  const [bankInfo, setBankInfo] = useState({})
  const [tempBankInfo, setTempBankInfo] = useState({})
  const loginInfo = JSON.parse(localStorage.getItem("logged"))
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(async () => {
  if(loginInfo){
    const res = await fetch(BASE_URL + `/profile/doctor/bankings/5546`, {
      headers: {
        "Content-type": "application/json",
        "authorization": `${loginInfo.token}`
      }
    })
    const result = await res.json()
    setBankInfo(result.bankings)
    setTempBankInfo(result.bankings)
  }
  }, [])


  const setupNewBanking = async () => {
    let obj = {}
    if (tempBankInfo.banking_type === "mobileWallet") {
      obj = {
        banking_type: tempBankInfo.banking_type,
        banking_name: tempBankInfo.banking_name,
        account_number: tempBankInfo.account_number
      }
    }
    else {
      obj = {
        banking_type: tempBankInfo.banking_type,
        banking_name: tempBankInfo.banking_name,
        account_number: tempBankInfo.account_number,
        account_holder_name: tempBankInfo.account_holder_name,
        routing_number: tempBankInfo.routing_number
      }
    }
    console.log(obj)
    const res = await fetch(BASE_URL + '/profile/doctor/bankings', {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "authorization": `${loginInfo.token}`
      },
      body: JSON.stringify(obj)
    })
    const result = await res.json()

    if (result.status === true) {
      enqueueSnackbar("Updated successfully")
    }
  }

  return <bankInfoContext.Provider value={{ bankInfo, setBankInfo, setupNewBanking, tempBankInfo, setTempBankInfo }}>{children}</bankInfoContext.Provider>
}

export const useBankInfoContext = () => {
  const { bankInfo, setBankInfo, setupNewBanking, tempBankInfo, setTempBankInfo } = useContext(bankInfoContext)


  return { bankInfo, setBankInfo, setupNewBanking, tempBankInfo, setTempBankInfo }

}