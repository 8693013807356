import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControlLabel, FormGroup, InputAdornment, TextField } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import Checkbox from '@mui/material/Checkbox';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import "./profile.scss"
import { useScheduleContext } from '../../context/profile/scheduleContext';


export default function SetupSchedule() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('body');

  const {
    schedule,
    tempSchedule,
    setTempSchedule,
    addSchedule
  } = useScheduleContext()


  const handleClickOpen = (scrollType) => () => {

    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleChange = () => {
    addSchedule()
  };


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  if (schedule.length > 0) {
    return (
      <div>
        <div >
          <TextField
            fullWidth
            onClick={handleClickOpen('body')}
            id="outlined-required"
            label=""
            size='medium'
            InputProps={{
              endAdornment: <InputAdornment position="end"><KeyboardArrowRightIcon /></InputAdornment>,
              readOnly: true,
            }}
          />
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Setup your schedule


            <p className='p-text'>While setting up schedule please check that Start Time is not after than End Time of schedule and also End time can't be after 11:59 pm of the day</p>
          </DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >


              <div className='setup-schedule setup-schedule-top'>
                <p className='p1'>Day</p>
                <p className='p2'>Start Time</p>
                <p className='p3'>End Time</p>
              </div>


              <div className='setup-schedule'>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Sunday" />
                </FormGroup>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={dayjs(tempSchedule[0].start_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "0": { start_time: newValue.$d, end_time: tempSchedule[0].end_time, day_id: tempSchedule[0].day_id, checked: tempSchedule[0].checked, day_name: tempSchedule[0].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <MobileTimePicker
                    value={dayjs(tempSchedule[0].end_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "0": { start_time: tempSchedule[0].start_time, end_time: timestamp, day_id: tempSchedule[0].day_id, checked: tempSchedule[0].checked, day_name: tempSchedule[0].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <div className='setup-schedule'>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Monday" />
                </FormGroup>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={dayjs(tempSchedule[1].start_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "1": { start_time: timestamp, end_time: tempSchedule[1].end_time, day_id: tempSchedule[1].day_id, checked: tempSchedule[1].checked, day_name: tempSchedule[1].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <MobileTimePicker
                    value={dayjs(tempSchedule[1].end_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "1": { start_time: tempSchedule[1].start_time, end_time: timestamp, day_id: tempSchedule[1].day_id, checked: tempSchedule[1].checked, day_name: tempSchedule[1].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>


              <div className='setup-schedule'>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Tuesday" />
                </FormGroup>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={dayjs(tempSchedule[2].start_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "2": { start_time: timestamp, end_time: tempSchedule[2].end_time, day_id: tempSchedule[2].day_id, checked: tempSchedule[2].checked, day_name: tempSchedule[2].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <MobileTimePicker
                    value={dayjs(tempSchedule[2].end_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "2": { start_time: tempSchedule[2].start_time, end_time: timestamp, day_id: tempSchedule[2].day_id, checked: tempSchedule[2].checked, day_name: tempSchedule[2].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <div className='setup-schedule'>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Wednesday" />
                </FormGroup>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={dayjs(tempSchedule[3].start_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "3": { start_time: timestamp, end_time: tempSchedule[3].end_time, day_id: tempSchedule[3].day_id, checked: tempSchedule[3].checked, day_name: tempSchedule[3].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <MobileTimePicker
                    value={dayjs(tempSchedule[3].end_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "3": { start_time: tempSchedule[3].start_time, end_time: timestamp, day_id: tempSchedule[3].day_id, checked: tempSchedule[3].checked, day_name: tempSchedule[3].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className='setup-schedule'>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Thursday" />
                </FormGroup>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={dayjs(tempSchedule[4].start_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "4": { start_time: timestamp, end_time: tempSchedule[4].end_time, day_id: tempSchedule[4].day_id, checked: tempSchedule[4].checked, day_name: tempSchedule[4].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <MobileTimePicker
                    value={dayjs(tempSchedule[4].end_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "4": { start_time: tempSchedule[4].start_time, end_time: timestamp, day_id: tempSchedule[4].day_id, checked: tempSchedule[4].checked, day_name: tempSchedule[4].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <div className='setup-schedule'>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Friday" />
                </FormGroup>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={dayjs(tempSchedule[5].start_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "5": { start_time: timestamp, end_time: tempSchedule[5].end_time, day_id: tempSchedule[5].day_id, checked: tempSchedule[5].checked, day_name: tempSchedule[5].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <MobileTimePicker
                    value={dayjs(tempSchedule[5].end_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "5": { start_time: tempSchedule[5].end_time, end_time: timestamp, day_id: tempSchedule[5].day_id, checked: tempSchedule[5].checked, day_name: tempSchedule[5].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>

              <div className='setup-schedule'>
                <FormGroup>
                  <FormControlLabel control={<Checkbox />} label="Saturday" />
                </FormGroup>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    value={dayjs(tempSchedule[6].start_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "6": { start_time: timestamp, end_time: tempSchedule[6].end_time, day_id: tempSchedule[6].day_id, checked: tempSchedule[6].checked, day_name: tempSchedule[6].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <MobileTimePicker
                    value={dayjs(tempSchedule[6].end_time)}
                    onChange={(newValue) => {
                      var date = new Date(newValue.$d);
                      var timestamp = date.getTime();
                      setTempSchedule({ ...tempSchedule, "6": { start_time: tempSchedule[6].start_time, end_time: timestamp, day_id: tempSchedule[6].day_id, checked: tempSchedule[6].checked, day_name: tempSchedule[6].day_name } })
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>


            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'red' } }}>Cancel</Button>
            <Button onClick={handleChange} color='secondary' variant="contained">Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    );

  }
}