import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { InputAdornment, TextField } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useProfileContext } from '../../context/profile/profileContext';


export default function SetupAppointmentFee() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const {profile} = useProfileContext()
  const {regular_fee,followup_fee} = profile

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
     
           <div > 
              <TextField
                    fullWidth
                    onClick={handleClickOpen('paper')}
                    id="outlined-required"
                    label=""
                    size='medium'
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><KeyboardArrowRightIcon/></InputAdornment>,
                        readOnly: true,
                      }}
                
                 
                />
           </div>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add Appointment Fee</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{width: 500}}
          >


          <TextField
                fullWidth
                id="outlined-required"
                label="Appointment Fee"
                defaultValue={regular_fee}
                size='medium'
            />
             <TextField
                fullWidth
                sx={{mt: 3,display:'block'}}
                id="outlined-required"
                label="Follow-up Fee"
                defaultValue={followup_fee}
                size='medium'
            />



          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">Cancel</Button>
          <Button onClick={handleClose} color='secondary' variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}