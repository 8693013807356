import { BASE_URL } from '../utils/constants'
import axios from 'axios';
import { useAuth } from './authContext';

import {
  createContext,
  useContext,
  useState,
  useEffect
} from "react";

const accountPaymentContext = createContext()

export const AccountContextProvider = ({ children }) => {
  const [accountPaymentInfo, setAccountPaymentInfo] = useState({})
  const auth = useAuth()
  const loginInfo = JSON.parse(localStorage.getItem('logged'))


  const accountInfoFun = async () => {
    if (loginInfo) {
      const accountInformations = await axios.get(
        BASE_URL + `/account/payment_info/${loginInfo.user_id}`, {
        headers: {
          'authorization': `${loginInfo.token}`
        }
      }
      )
      setAccountPaymentInfo(accountInformations.data.payment_info)
      console.log('mount hoie')
    }

    return accountInfoFun
  }

  // useEffect(()=> {
  //        accountInformation()
  // },[])

  return <accountPaymentContext.Provider value={{ accountInfoFun, accountPaymentInfo }}>{children}</accountPaymentContext.Provider>
}




export const useAccountPaymentContext = () => {
  const {
    accountInfoFun,
    accountPaymentInfo
  } = useContext(accountPaymentContext)

  return {
    accountInfoFun,
    accountPaymentInfo
  }
}