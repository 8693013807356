import { BASE_URL } from '../../utils/constants'
import { useSnackbar } from 'notistack'
import {
    createContext,
    useContext,
    useEffect,
    useState
} from "react";

const profileContext = createContext()

export const ProfileContextProvider = ({ children }) => {
    const [profile, setProfile] = useState({})
    const [profilePicture, setProfilePicture] = useState({})
    const [showImg, setShowImg] = useState({db: true, up: false,uploadButton: false})
    const loginInfo = JSON.parse(localStorage.getItem("logged"))
    const { enqueueSnackbar } = useSnackbar()


    useEffect(async () => {
       if(loginInfo){
        const res = await fetch(BASE_URL + `/profile/doctor/${loginInfo.user_id}/info`, {
            headers: {
                "Content-type": "application/json",
                "authorization": `${loginInfo.token}`
            }
        })
        const result = await res.json()
        setProfile(result.doctor)
       }
    }, [])



    const updateProfile = async () => {
        const newProfile = {
            name: profile.name,
            designation: profile.designation,
            current_workplace: profile.current_workplace,
            profile_description: profile.profile_description,
            experience: (profile.experience).toString()
        }
        const res = await fetch(BASE_URL + `/profile/doctor/update`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "authorization": `${loginInfo.token}`
            },
            body: JSON.stringify(newProfile)
        })

            const result = await res.json()
            if (result.status === true) {
                enqueueSnackbar("Successfully updated profile", { variant: 'success' }, { autoHideDuration: 5000 })

            }
    }


    const update_profile_image =async()=>{
         let formData = new FormData();
         formData.append('image', profilePicture.pictureAsFile);
    
           const res = await fetch(BASE_URL+"/profile/update/image",{
                    method:"POST",
                    headers: {
                        "authorization":`${loginInfo.token}`
                    },
                    body: formData
           })
           const result = await res.json()
           if(result.status === true){
            setShowImg({db: showImg.db,up: showImg.up, uploadButton: false})
            enqueueSnackbar("Successfully updated profile picture")
           
         //   window.location.reload("http://localhost:3000/profilesettings")
                  
           }
           console.log(result)
    }

    return <profileContext.Provider value={{ profile, setProfile, updateProfile,update_profile_image ,profilePicture,setProfilePicture,showImg,setShowImg}}>{children}</profileContext.Provider>
}

export const useProfileContext = () => {
    const {
        profile,
        setProfile,
        updateProfile,
        update_profile_image,
        profilePicture,
        setProfilePicture,
        showImg,
        setShowImg
    } = useContext(profileContext)


    return {
        profile,
        setProfile,
        updateProfile,
        update_profile_image,
        profilePicture,
        setProfilePicture,
        showImg,
        setShowImg
    }
}